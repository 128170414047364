import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from '../service';
import { User } from "../model/user";
import { Observable, Subscription } from 'rxjs';

@Injectable()
export class LoginGuard implements CanActivate {

    constructor( private router: Router, private userService: UserService ) { }

    canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): boolean {
        if ( this.userService.getCurrentUser() ) {
            return true;
        } else {
            this.router.navigate( ['/login'], { queryParams: { returnUrl: state.url } } );
            return false;
        }
    }
}
