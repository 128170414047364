import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from "@angular/forms";
import { Subscription, Observable, BehaviorSubject, of } from "rxjs";
import { MediaMatcher } from '@angular/cdk/layout';

import { ToasterService, ToasterConfig } from 'angular2-toaster';

import {
    Driver,
    Result,
    Track
} from '../model';
import {
    DriverService,
    QualifyingService,
    ResultService,
    SelectionService,
    TrackService,
    UserService
} from "../service";

import { CustomValidators } from "../validators/custom-validators";

@Component( {
    selector: 'app-result',
    templateUrl: './result.component.html',
    styleUrls: ['./result.component.css']
} )
export class ResultComponent implements OnInit, OnDestroy {

    @Input() selectedTrack: Track;
    trackSubscription: Subscription;
    result: Result;
    drivers: Driver[];
    mobileQuery: MediaQueryList;
    private _mobileQueryListener: () => void;
    myForm: FormGroup;

    private loadingSubject = new BehaviorSubject<boolean>( false );

    public loading$ = this.loadingSubject.asObservable();

    public toasterConfig: ToasterConfig = new ToasterConfig( {
        animation: 'fade',
        positionClass: 'toast-center',
        limit: 1
    } );

    constructor( private fb: FormBuilder, private selectionService: SelectionService, private resultService: ResultService, public userService: UserService, media: MediaMatcher, private trackService: TrackService, private qualifyingService: QualifyingService, private driverService: DriverService, private toasterService: ToasterService ) {
        this.mobileQuery = media.matchMedia( '(max-width: 1200px)' );
        this.mobileQuery.addListener( this._mobileQueryListener );

        this.trackSubscription = selectionService.selectedTrack.subscribe(
            track => {
                this.selectedTrack = track;
                this.getResult();
                this.getDrivers( track.season );
            } );
    }

    ngOnInit() {
        this.myForm = this.fb.group( {
            'result': new FormGroup( {
                'results': new FormArray( [new FormControl( '', Validators.required ), new FormControl( '', Validators.required ), new FormControl( '', Validators.required ), new FormControl( '', Validators.required ), new FormControl( '', Validators.required ), new FormControl( '', Validators.required ), new FormControl( '', Validators.required ), new FormControl( '', Validators.required ), new FormControl( '', Validators.required ), new FormControl( '', Validators.required )] ),
                'fastestLap': new FormControl( '', Validators.required )
            }, CustomValidators.result )
        } );
    }

    ngOnDestroy() {
        // prevent memory leak when component destroyed
        this.trackSubscription.unsubscribe();
    }

    getResult() {
        this.loadingSubject.next( true );
        this.resultService.getResult( this.selectedTrack.season, this.selectedTrack.trackNo ).subscribe(
            result => {
                this.result = result;
                this.loadingSubject.next( false );
            },
            error => {
                this.setResultWithQuali( this.selectedTrack );
            }
        );
    }

    getDrivers( season ): void {
        this.driverService.getDrivers( season ).then( drivers => this.drivers = drivers );
    }

    setResultWithQuali( track ): Observable<Result> {
        this.qualifyingService.getQualifying( track.season, track.trackNo ).subscribe(
            quali => {
                this.result = new Result();
                this.result.track = track;
                this.result.season = track.season;
                this.result.positions = quali.positions.slice( 0, 10 );
                this.result.fastestLap = quali.positions[0];
                this.loadingSubject.next( false );
            }, error => {
                this.result = new Result();
                this.result.track = track;
                this.result.season = track.season;
                this.loadingSubject.next( false );
            } );
        return of( this.result );
    }
    onSave() {
        this.loadingSubject.next( true );
        if ( this.result.id > 0 ) {
            this.resultService.updateResult( this.result ).subscribe(
                result => {
                    this.result = result;
                    this.loadingSubject.next( false );
                    this.toasterService.pop( 'success', 'Ergebnis gespeichert', 'Das Ergebnis für das Rennen ' + this.result.track.trackName + ' wurde erfolgreich gespeichert.' );
                },
                error => {
                    this.toasterService.pop( 'error', 'Fehler', 'Das Ergebnis für das Rennen ' + this.result.track.trackName + ' konnte nicht gespeichert werden.' );
                }
            );
        } else {
            this.resultService.saveResult( this.result ).subscribe(
                result => {
                    this.result = result;
                    this.loadingSubject.next( false );
                    this.toasterService.pop( 'success', 'Ergebnis gespeichert', 'Das Ergebnis für das Rennen ' + this.result.track.trackName + ' wurde erfolgreich gespeichert.' );
                },
                error => {
                    this.toasterService.pop( 'error', 'Fehler', 'Das Ergebnis für das Rennen ' + this.result.track.trackName + ' konnte nicht gespeichert werden.' );
                }
            );
        }
    }

    compareDriver( d1: Driver, d2: Driver ): boolean {
        return d1 && d2 ? d1.id === d2.id : d1 === d2;
    }

}
