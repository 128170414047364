import { Component, OnInit, Input, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, NG_VALIDATORS, FormControl, FormBuilder, Validators, FormArray } from "@angular/forms";

import { Subscription ,  BehaviorSubject } from "rxjs";

import { ToasterService, ToasterConfig } from 'angular2-toaster';

import { Driver } from '../../model/driver';
import { DriverService } from '../../service/driver.service';
import { Track } from "../../model/track";
import { SelectionService } from "../../service/selection.service";
import { Prediction } from "../../model/prediction";
import { PredictionService } from "../../service/prediction.service";
import { User } from "../../model/user";
import { QualifyingService } from "../../service/qualifying.service";
import { CustomValidators } from "../../validators/custom-validators";
import { UserService } from "../../service/index";

@Component( {
    selector: 'prediction-admin',
    templateUrl: './prediction-admin.component.html',
    styleUrls: ['./prediction-admin.component.css'],
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => PredictionAdminComponent ), multi: true }
    ]
} )

export class PredictionAdminComponent implements OnInit, OnDestroy {
    drivers: Driver[];
    season: number;
    @Input() selectedTrack: Track;
    trackSubscription: Subscription;
    @Input() selectedUser: User;
    userSubscription: Subscription;
    prediction: Prediction;
    saveErrors: string;
    @Input() _prediction: Prediction;
    predictionKeys = new Array( 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 );
    myForm: FormGroup;
    propagateChange: any = () => { };

    private loadingSubject = new BehaviorSubject<boolean>( false );
    public loading$ = this.loadingSubject.asObservable();

    public toasterConfig: ToasterConfig = new ToasterConfig( {
        animation: 'fade',
        positionClass: 'toast-center',
        limit: 1
    } );

    constructor( private fb: FormBuilder, private driverService: DriverService, private selectionService: SelectionService, private predictionService: PredictionService, private qualifyingService: QualifyingService, private toasterService: ToasterService, private userService: UserService ) {
        this.myForm = this.fb.group( {
//            'track': new FormControl( '', CustomValidators.track ),
            'prediction': new FormGroup( {
                'predictions': new FormArray( [new FormControl( '', Validators.required ), new FormControl( '', Validators.required ), new FormControl( '', Validators.required ), new FormControl( '', Validators.required ), new FormControl( '', Validators.required ), new FormControl( '', Validators.required ), new FormControl( '', Validators.required ), new FormControl( '', Validators.required ), new FormControl( '', Validators.required ), new FormControl( '', Validators.required )] ),
                'fastestLap': new FormControl( '', Validators.required )
            }, CustomValidators.prediction )
        } );
        this.trackSubscription = selectionService.selectedTrack.subscribe(
            track => {
                this.prediction = null;
                this.selectedTrack = track;
                this.getPrediction();
//                this.disableOrEnablePredictions( track );
            } );
        this.userSubscription = selectionService.selectedUser.subscribe(
                user => {
                    this.prediction = null;
                    this.selectedUser = user;
                    if (this.selectedTrack) {
                        this.getPrediction();
                    }
                }
        );
    }

    getDrivers(): void {
        this.driverService.getDrivers( this.season ).then( drivers => this.drivers = drivers );
    }

    getPrediction(): void {
        this.loadingSubject.next( true );
        if ( this.selectedUser ) {
            this.predictionService.getPrediction( this.season, this.selectedTrack.trackNo, this.selectedUser.userID ).subscribe(
                prediction => {
                    this.prediction = prediction;
                    this.loadingSubject.next( false );
                },
                error => this.setPredictionWithQuali()
            );
            this.propagateChange( this.prediction );
        }
    }

    setPredictionWithQuali(): Promise<Prediction> {
        this.qualifyingService.getQualifying( this.selectedTrack.season, this.selectedTrack.trackNo ).subscribe(
            quali => {
                this.prediction = new Prediction();
                this.prediction.predictions = quali.positions;
                this.prediction.fastestLap = this.prediction.predictions[0];
                this.loadingSubject.next( false );
            },
            error => {
                this.prediction = new Prediction();
                this.loadingSubject.next( false );
            }
        );
        return Promise.resolve( this.prediction );
    }

//    disableOrEnablePredictions( track ): void {
//        if ( CustomValidators.isTrackValid( track ) ) {
//            this.myForm.get( "prediction" ).enable();
//        } else {
//            this.myForm.get( "prediction" ).disable();
//        }
//    }

    onSave(): void {
        this.loadingSubject.next( true );
        this.prediction.track = this.selectedTrack;
        this.prediction.user = this.selectedUser;
        this.prediction.season = this.season;
        if ( this.prediction.id > 0 ) {
            this.predictionService.updatePrediction( this.prediction ).then( value => {
                this.prediction = value;
                this.loadingSubject.next( false );
                this.toasterService.pop( 'success', 'Tipp gespeichert', 'Der Tipp für das Rennen ' + this.prediction.track.trackName + ' wurde erfolgreich gespeichert.' );
                //              this.myForm.controls.prediction.reset();
                //TODO: redirect to tippübersicht?
            } ).catch( error => {
                this.saveErrors = error;
                this.loadingSubject.next( false );
                this.toasterService.pop( 'error', 'Fehler', 'Der Tipp für das Rennen ' + this.prediction.track.trackName + ' konnte nicht gespeichert werden.' );
            } );
        } else {
            this.predictionService.savePrediction( this.prediction ).then( value => {
                this.prediction = value;
                this.loadingSubject.next( false );
                this.toasterService.pop( 'success', 'Tipp gespeichert', 'Der Tipp für das Rennen ' + this.prediction.track.trackName + ' wurde erfolgreich gespeichert.' );
                //                this.myForm.controls.prediction.reset();
                //TODO: redirect to tippübersicht?
            } ).catch( error => {
                this.saveErrors = error;
                this.loadingSubject.next( false );
                this.toasterService.pop( 'error', 'Fehler', 'Der Tipp für das Rennen ' + this.prediction.track.trackName + ' konnte nicht gespeichert werden.' );
            } );
        }

    }

    ngOnInit(): void {
        if ( this.season == null ) {
            this.season = new Date().getFullYear();
        }       
        this.getDrivers();
    }

    ngOnDestroy() {
        // prevent memory leak when component destroyed
        this.trackSubscription.unsubscribe();
        this.userSubscription.unsubscribe();
    }

    compareDriver( d1: Driver, d2: Driver ): boolean {
        return d1 && d2 ? d1.id === d2.id : d1 === d2;
    }

    validate( c: FormGroup ) {
        return CustomValidators.prediction( c.value );
    }

}
