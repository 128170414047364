import { Component } from '@angular/core';

@Component({
  selector: 'calendar',
template: `
    <iframe src="https://calendar.google.com/calendar/embed?height=600&amp;title=Termine&amp;wkst=1&amp;mode=AGENDA&amp;bgcolor=%23FFFFFF&amp;src=nm48tmurnvjituiue4v474pftk%40group.calendar.google.com&amp;color=%23182C57&amp;src=bfdvthouos8ruacs0cr6l4nrke057c24%40import.calendar.google.com&amp;color=%23B1365F&amp;ctz=Europe%2FBerlin" style="border-width:0" width="800" height="600" frameborder="0" scrolling="no"></iframe>
`
})

export class CalendarComponent {
}
