
<form [formGroup]="myForm">
	<div class="spinner-container" *ngIf="loading$ | async">
		<mat-spinner></mat-spinner>
	</div>
	<track-selector></track-selector>
	<br />
	<h4>Ergebnis</h4>
	<div formGroupName="result" class="result-inputs">
		<toaster-container [toasterconfig]="toasterConfig"></toaster-container>
		<div *ngIf="(result)" class="d-flex align-content-start flex-wrap">
			<div *ngFor="let pos of [1,2,3,4,5,6,7,8,9,10]; let i=index"
				formArrayName="results" class="input-field"
			>
				<mat-form-field> <mat-select required
					placeholder="Platz {{(pos)}}" [compareWith]="compareDriver"
					[(ngModel)]="result.positions[i]" formControlName="{{i}}"
					id="pos{{i}}" class="result-input"
				> <mat-option *ngFor="let driver of drivers" [value]="driver">{{driver.driverName}}</mat-option>
				</mat-select> </mat-form-field>
			</div>
			<mat-form-field class="input-field"> <mat-select
				required placeholder="Schn. Runde" [compareWith]="compareDriver"
				[ngModel]="result.fastestLap"
				(ngModelChange)="result.fastestLap=$event"
				formControlName="fastestLap" class="result-input"
			> <mat-option *ngFor="let driver of drivers" [value]="driver">{{driver.driverName}}</mat-option>
			</mat-select> </mat-form-field>
		</div>
	</div>
	<div *ngIf="myForm.get('result').errors" class="alert alert-danger">Fehler:
		{{myForm.get("result").errors?.result.message}}</div>
	<button *ngIf="userService.isAdmin()" class="save-btn btn btn-primary"
		(click)="onSave()"
		[disabled]="myForm.invalid || myForm.pending || myForm.disabled"
	>Ergebnis speichern</button>
</form>
