<form [formGroup]="myForm">
	<div class="form-group">
		<toaster-container [toasterconfig]="toasterConfig"></toaster-container>
		<div class="spinner-container" *ngIf="loading$ | async">
			<mat-spinner style="margin: 0 auto;"></mat-spinner>
		</div>
		<track-selector></track-selector>
		<br />
		<user-selector></user-selector>
		<br />
		<h4>Tippnachtrag</h4>
		<div formGroupName="prediction" class="form-group">
			<div *ngIf="(prediction) && (prediction.predictions)"
				class="d-flex align-content-start flex-wrap"
			>
				<div *ngFor="let predictionKey of predictionKeys; let i = index"
					formArrayName="predictions" class="input-field"
				>
					<mat-form-field> <mat-select required
						placeholder="Platz {{predictionKey}}"
						[compareWith]="compareDriver"
						[(ngModel)]="prediction.predictions[i]" formControlName="{{i}}"
						id="pos{{i}}"
					> <mat-option *ngFor="let driver of drivers" [value]="driver">{{driver.driverName}}</mat-option>
					</mat-select> </mat-form-field>
				</div>
				<mat-form-field *ngIf="(prediction)" class="input-field">
				<mat-select required placeholder="Schnellste Runde"
					[compareWith]="compareDriver" [ngModel]="prediction?.fastestLap"
					(ngModelChange)="prediction.fastestLap=$event"
					formControlName="fastestLap"
				> <mat-option *ngFor="let driver of drivers" [value]="driver">{{driver.driverName}}</mat-option>
				</mat-select> </mat-form-field>
			</div>
		</div>
		<div *ngIf="myForm.get('prediction').errors" class="alert alert-danger">Fehler:
				{{myForm.get("prediction").errors?.prediction.message}}
		</div>
		<button class="btn btn-primary" (click)="onSave()"
			[disabled]="myForm.disabled || myForm.invalid || myForm.pending"
		>Tipp speichern</button>
	</div>
</form>
