import { Injectable } from '@angular/core';



import { ApiService } from './api.service';

import { Track } from '../model/track';
import { Observable } from "rxjs";

@Injectable()
export class TrackService {
    private tracksUrl = 'api/tracks';  // URL to web api

    constructor( private apiService: ApiService ) { }

    getTracks( season: number ): Promise<Track[]> {

        return this.apiService.get<Track[]>( this.tracksUrl, { season: season } )
            .toPromise();
    }

    getNextTrack(): Promise<Track> {
        const url = `${this.tracksUrl}/next`;
        return this.apiService.get<Track>( url )
            .toPromise();
    }

    getLiveTrack(): Observable<Track> {
        return this.apiService.get<Track>( this.tracksUrl + "/live" );
    }

    getSeasons(): Observable<number[]> {
        return this.apiService.get( this.tracksUrl + "/seasons" );
    }
    
    getLastTrack(season: number): Observable<Track> {
        return this.apiService.get<Track>( this.tracksUrl + "/last", { season: season } );
    }
}
