<div>
	<season-selector class="col-md-3"></season-selector>
</div>
<div class="spinner-container" *ngIf="dataSource.loading$ | async">
    <mat-spinner style="margin: 0 auto;"></mat-spinner>
</div>
<div *ngIf="(bonusQuestions && bonusQuestions.length < 1)">
	Es gibt keine Bonusfragen für diese Saison.
</div>
<mat-expansion-panel *ngIf="(bonusQuestions && bonusQuestions.length > 0)" [expanded]="!mobileQuery.matches">
	<mat-expansion-panel-header>
  		<mat-panel-title>
    		Bonusfragen {{ season }}
  		</mat-panel-title>
	</mat-expansion-panel-header>
		<mat-table #table [dataSource]="bonusQuestions" class="mat-elevation-z8">
			  <ng-container *ngFor="let bonusColumn of bonusQuestionsColumns" [matColumnDef]="bonusColumn.columnDef">
				  <div *ngIf="bonusColumn.columnDef !== 'result'">
				    <mat-header-cell *matHeaderCellDef><span [matTooltip]="bonusColumn.tooltip"> {{bonusColumn.header}} </span></mat-header-cell>
				    <mat-cell *matCellDef="let element"> 
				    	<div *ngIf="!mobileQuery.matches">{{bonusColumn.dataName(element)}}</div>
				    	<div *ngIf="mobileQuery.matches">{{(bonusColumn.shortData) ? bonusColumn.shortData(element) : bonusColumn.dataName(element)}}</div>
				    </mat-cell>
				  </div>
				  <div *ngIf="bonusColumn.columnDef === 'result'">
				    <mat-header-cell *matHeaderCellDef><span [matTooltip]="bonusColumn.tooltip"> {{bonusColumn.header}} </span></mat-header-cell>
				    <mat-cell *matCellDef="let element; let i = index"> 
				    	<div *ngIf="(bonusResults[i]) && !mobileQuery.matches">{{bonusResults[i]?.result?.driverName}}</div>
				    	<div *ngIf="(bonusResults[i]) && mobileQuery.matches">{{bonusResults[i]?.result?.trigram}}</div>
				    	<div *ngIf="!(bonusResults[i])"> - </div>
				    </mat-cell>
				  </div>
			  </ng-container>
			<mat-header-row *matHeaderRowDef="displayedBonusQuestionColumns; sticky: true"></mat-header-row>
  			<mat-row *matRowDef="let bonusQuestion; columns: displayedBonusQuestionColumns;"></mat-row>
		</mat-table>
</mat-expansion-panel>
<div *ngIf="(bonusQuestions && bonusQuestions.length > 0)" class="table-container mat-elevation-z8">
	<mat-table #table [dataSource]="dataSource">
		
		<ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef" [sticky]="column.sticky === 'sticky'" [stickyEnd]="column.sticky === 'stickyEnd'">
			<div *ngIf="!column.columnDef.startsWith('prediction') && column.columnDef !== 'totalwins' && column.columnDef !== 'totalpoints' && column.columnDef !== 'positionDiff'">
				<mat-header-cell *matHeaderCellDef><span [matTooltip]="column.tooltip"> {{column.header}} </span></mat-header-cell>
				<mat-cell *matCellDef="let row">
					<div *ngIf="(row)" >
					{{ column.dataName(row) }}
					</div>
				</mat-cell>
			</div>
			<div *ngIf="column.columnDef === 'positionDiff'">
				<mat-header-cell *matHeaderCellDef><span [matTooltip]="column.tooltip"> {{column.header}} </span></mat-header-cell>
				<mat-cell *matCellDef="let row">
					<div *ngIf="row.positionDiff > 0" class="green">
						<mat-icon>arrow_drop_up</mat-icon>
						<span class="trend diff">{{ row.positionDiff }}</span>	
					</div>
					<div *ngIf="row.positionDiff < 0" class="red">						
						<mat-icon>arrow_drop_down</mat-icon>
						<span class="trend diff">{{ row.positionDiff }}</span>
					</div>
				</mat-cell>
			</div>
			<div *ngIf="column.columnDef === 'totalwins' || column.columnDef === 'totalpoints'">
				<mat-header-cell *matHeaderCellDef><span [matTooltip]="column.tooltip"> {{column.header}} </span></mat-header-cell>
				<mat-cell *matCellDef="let row">
					<div *ngIf="(row)" >
					{{ column.dataName(row)  | number:'1.0-2':'de-DE'}}
					</div>
				</mat-cell>
			</div>		
			<div *ngIf="column.columnDef.startsWith('prediction')">
				<mat-header-cell *matHeaderCellDef class="prediction"><span [matTooltip]="column.tooltip"> {{column.header}} </span></mat-header-cell>
				<mat-cell *matCellDef="let row" class="prediction">				
					<div *ngIf="(row.bonusPredictions && row.bonusPredictions[column.dataName] && row.bonusPredictions[column.dataName].bonusPredictionDriver)  && (!mobileQuery.matches)" [class]="(bonusResults[column.dataName] && row.user) ? ((row.bonusPredictionPoints[column.dataName] && row.bonusPredictionPoints[column.dataName].points > 0) ? 'green' : 'lightgray') : ''">
						{{ row.bonusPredictions[column.dataName]?.bonusPredictionDriver?.driverName }}
						<span *ngIf="(bonusResults[column.dataName] && row.bonusPredictionPoints[column.dataName] && row.bonusPredictionPoints[column.dataName].points > 0)" class="punkte">{{row.bonusPredictionPoints[column.dataName].points}}</span>
					</div>
					<div *ngIf="(row.bonusPredictions && row.bonusPredictions[column.dataName]  && row.bonusPredictions[column.dataName].bonusPredictionDriver)  && (mobileQuery.matches)" [class]="(bonusResults[column.dataName] && row.user) ? ((row.bonusPredictionPoints[column.dataName] && row.bonusPredictionPoints[column.dataName].points > 0) ? 'green' : 'lightgray') : ''">
						{{ row.bonusPredictions[column.dataName]?.bonusPredictionDriver?.trigram }}
						<span *ngIf="(bonusResults[column.dataName] && row.bonusPredictionPoints[column.dataName] && row.bonusPredictionPoints[column.dataName].points > 0)" class="punkte">{{row.bonusPredictionPoints[column.dataName].points}}</span> 
					</div>
					<div *ngIf="(row.bonusPredictions && !row.bonusPredictions[column.dataName])"></div>
					<div *ngIf="(row.bonusPredictions && row.bonusPredictions[column.dataName] && !row.bonusPredictions[column.dataName].bonusPredictionDriver)"> - </div>
				</mat-cell>
			</div>
		</ng-container>
		
		<mat-header-row	*matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
		<mat-row *matRowDef="let bonusOverview; columns: displayedColumns;"
			[ngClass]="{'result': (!bonusOverview.user), 'user': (bonusOverview.user) && bonusOverview.user.userID == userService.getCurrentUser().userID}"></mat-row>
	</mat-table>
</div>