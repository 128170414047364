import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
//material
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToasterModule, ToasterService } from 'angular2-toaster';
import { MediaMatcher } from '@angular/cdk/layout';

import { MqIfDirective } from './directives/mq-if.directive';

import { AppComponent } from './app.component';
import { AppRoutingModule } from "./app-routing.module";
import { LoginComponent } from "./login/login.component";

import { ChangePasswordComponent } from "./change-password";
import {
    CalendarComponent,
    TrackSelectorComponent,
    SeasonSelectorComponent,
    UserSelectorComponent,
    PageNotFoundComponent,
    QualifyingComponent,
    HeaderComponent,
    SideNavComponent
} from './component';
import { SidenavService } from './component/side-nav/side-nav.service';
import {
    PredictionComponent,
    BonusPredictionComponent,
    PredictionAdminComponent
} from './prediction';
import { RaceOverviewComponent } from './race-overview';
import { BonusOverviewComponent } from './bonus-overview';
import { QualifyingResultComponent } from './qualifying-result';
import { BonusResultComponent } from './bonus-result';
import { LiveOverviewComponent } from './live-overview';
import { AccountMenuComponent } from './component/header/account-menu/account-menu.component';
import { LoginGuard, GuestGuard, AdminGuard } from './guard';

import {
    ApiService,
    AuthService,
    BonusService,
    UserService,
    ConfigService,
    DriverService,
    PredictionService,
    QualifyingService,
    RaceOverviewService,
    ResultService,
    SelectionService,
    TrackService
} from './service';

export function initUserFactory(userService: UserService) {
    return () => userService.initUser();
}

// Locale
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { ResultComponent } from './result/result.component';
//import { BonusOverviewComponent } from './bonus-overview/bonus-overview.component';
registerLocaleData( localeDe, 'de-DE' );

@NgModule( {
    declarations: [
        AppComponent,
        CalendarComponent,
        LoginComponent,
        PageNotFoundComponent,
        PredictionComponent,
        PredictionAdminComponent,
        QualifyingComponent,
        TrackSelectorComponent,
        SeasonSelectorComponent,
        UserSelectorComponent,
        HeaderComponent,
        SideNavComponent,
        AccountMenuComponent,
        ChangePasswordComponent,
        BonusPredictionComponent,
        RaceOverviewComponent,
        QualifyingResultComponent,
        BonusResultComponent,
        LiveOverviewComponent,
        MqIfDirective,
        ResultComponent,
        BonusOverviewComponent
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        MatMenuModule,
        MatTooltipModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatToolbarModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatOptionModule,
        MatListModule,
        MatSelectModule,
        MatTableModule,
        MatGridListModule,
        MatSidenavModule,
        MatExpansionModule,
        MatCheckboxModule,
        ToasterModule
    ],
    providers: [
        LoginGuard,
        GuestGuard,
        AdminGuard,
        AuthService,
        ApiService,
        BonusService,
        UserService,
        ConfigService,
        TrackService,
        DriverService,
        SelectionService,
        PredictionService,
        QualifyingService,
        RaceOverviewService,
        ResultService,
        ToasterService,
        SidenavService,
        MediaMatcher,
        { provide: LOCALE_ID, useValue: 'de-DE' },
        {
            'provide': APP_INITIALIZER,
            'useFactory': initUserFactory,
            'deps': [UserService],
            'multi': true
          }
    ],
    bootstrap: [AppComponent]
} )
export class AppModule { }
