import { DataSource } from '@angular/cdk/collections';
import { Observable ,  Subscription ,  BehaviorSubject ,  of } from 'rxjs';
import { catchError, flatMap, finalize } from "rxjs/operators";

import {
    Driver,
    RaceOverview,
    Result,
    Track,
    User
} from '../model';
import {
    RaceOverviewService,
    SelectionService,
    ResultService
} from "../service";

export class RaceOverviewDataSource extends DataSource<RaceOverview>{

    private raceOverviewsSubject = new BehaviorSubject<RaceOverview[]>( [] );

    private loadingSubject = new BehaviorSubject<boolean>( false );

    public loading$ = this.loadingSubject.asObservable();
    
    raceOverviews: RaceOverview[];

    constructor( private raceOverviewService: RaceOverviewService, private resultService: ResultService ) {
        super();
    }

    connect(): Observable<RaceOverview[]> {
        return this.raceOverviewsSubject.asObservable();
    }

    disconnect() {
        this.raceOverviewsSubject.complete();
    }

    getRaceOverviews( season, track ) {
        this.loadingSubject.next( true );
        //        this.raceOverviewService.getRaceOverviews( 2016, 14 ).subscribe( raceOverviews => this.raceOverviewsSubject.next( raceOverviews ), error => of( [] ) );
        let tmpRaceOverviews = new Array<RaceOverview>();
        let resultRaceOverview = new RaceOverview();
        this.resultService.getResult( season, track.trackNo ).pipe(
            flatMap( result => {
                resultRaceOverview.predictions = result.positions;
                resultRaceOverview.fastestLap = result.fastestLap;
                tmpRaceOverviews.push( resultRaceOverview );
                return this.raceOverviewService.getRaceOverviews( season, track.trackNo );
            } ), catchError(( error ) => {
                return this.raceOverviewService.getRaceOverviews( season, track.trackNo );
            } ), finalize(() => {
                this.loadingSubject.next( false );
            } )
        ).subscribe( raceOverviews => {
            this.raceOverviewsSubject.next( tmpRaceOverviews.concat( raceOverviews ) );
            this.raceOverviews = raceOverviews;
        }
            );

    }

    getLiveOverviews( result ) {
        this.loadingSubject.next( true );
        //        this.raceOverviewService.getRaceOverviews( 2016, 14 ).subscribe( raceOverviews => this.raceOverviewsSubject.next( raceOverviews ), error => of( [] ) );
        let tmpRaceOverviews = new Array<RaceOverview>();
        let resultRaceOverview = new RaceOverview();
        resultRaceOverview.predictions = result.positions;
        resultRaceOverview.fastestLap = result.fastestLap;
        tmpRaceOverviews.push( resultRaceOverview );
        // TODO: change to simulate
        this.raceOverviewService.simulate( result ).subscribe(
            raceOverviews => {
                this.raceOverviewsSubject.next( tmpRaceOverviews.concat( raceOverviews ) );
                this.loadingSubject.next( false );
            }
        );
    }

}

