<!--The content below is only a placeholder and can be replaced.-->
<!-- <div class="form-group row"> -->
	<!--     <label class="col-sm-2 col-form-label" for="selectTrack" style="background-color:lightgray">Rennen:</label> -->
	<!--     <select class="form-control col-sm-2" [compareWith]="compareFn" [(ngModel)]="_selectedTracks" (change)="onChange()" id="selectTrack"> -->
	<!--       <option *ngFor="let track of tracks" [ngValue]="track">{{track.trackName}}</option> -->
	<!--     </select> -->
	<mat-form-field>
		<mat-select placeholder="Rennen" [compareWith]="compareFn" [(ngModel)]="_selectedTracks"
					name="selectTrack" (selectionChange)="onChange()" id="selectTrack">
			<mat-option *ngFor="let track of tracks" [value]="track">{{track.trackName}}</mat-option>
		</mat-select>
	</mat-form-field>
<!-- </div> -->
