import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../service';
import { User } from "../model/user";
import { Observable, Subscription } from 'rxjs';

@Injectable()
export class AdminGuard implements CanActivate {

    constructor( private router: Router, private userService: UserService ) {
    }

    canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): boolean {
        if ( this.userService.getCurrentUser() ) {
            if ( JSON.stringify( this.userService.getCurrentUser().userRoles ).search( 'Admin' ) !== -1 ) {
                return true;
            } else {
                this.router.navigate( ['/403'] );
                return false;
            }

        } else {
            this.router.navigate( ['/login'], { queryParams: { returnUrl: state.url } } );
            return false;
        }
    }
}

