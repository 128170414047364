
import { throwError, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, flatMap } from "rxjs/operators";

import {
    BonusQuestion,
    BonusOverview,
    BonusPrediction,
    BonusResult,
    User
} from '../model';
import { UserService } from "./user.service";
import { ApiService } from './api.service';

@Injectable()
export class BonusService {
    private bonusUrl = 'api/bonus';  // URL to web api

    constructor( private apiService: ApiService, private userService: UserService ) { }
    getBonusQuestions( season: number ): Observable<BonusQuestion[]> {

        return this.apiService.get<BonusQuestion[]>( this.bonusUrl + '/bonusquestions', { season: season } ).pipe(
            catchError( this.handleError ) );
    }

    getBonusPrediction( bonusQuestion: BonusQuestion ): Observable<BonusPrediction> {
        let currUser: User;
        return this.userService.getUserInfo().pipe(
            flatMap( currentUser => {
                currUser = currentUser;
                return this.apiService.get<BonusPrediction>( this.bonusUrl + '/predictions/user/' + currUser.userID + '/bonusQuestionId/' + bonusQuestion.bonusId );
            }
            ),
            catchError(( error ) => {
                // if it's 404 return a dummy BonusPrediction
                if ( error.status == 404 ) {
                    //                    console.log( "catched error in getBonusPrediciton...currUser: " + currUser.userID );
                    let newBonusPrediction = new BonusPrediction();
                    newBonusPrediction.user = currUser;
                    newBonusPrediction.bonusQuestion = bonusQuestion;
                    return of( newBonusPrediction );
                } else {
                    this.handleError;
                }
            } )

        );
    }

    getBonusPredictionsBySeasonAndUser( season: number, user: User ): Observable<BonusPrediction[]> {
        //        let currUser: User;
        //        return this.userService.getUserInfo().pipe(
        //            flatMap( currentUser => {
        //                currUser = currentUser;
        //                return this.apiService.get<BonusPrediction[]>( this.bonusUrl + '/predictions/user/' + currUser.userID + '/season/' + season );
        //            }
        //            ),
        //            catchError( this.handleError )
        //        );
        return this.apiService.get<BonusPrediction[]>( this.bonusUrl + '/predictions/user/' + user.userID + '/season/' + season );
    }

    saveBonusPrediction( bonusPrediction: BonusPrediction ) {
        return this.apiService.post<BonusPrediction>( this.bonusUrl + '/predictions', JSON.stringify( bonusPrediction ) );
    }

    saveBonusPredictions( bonusPredictions: BonusPrediction[] ): Observable<BonusPrediction[]> {
        return this.apiService.post<BonusPrediction[]>( this.bonusUrl + '/predictions', JSON.stringify( bonusPredictions ) );
    }

    getBonusOverviews( season: string, trackNo: number ): Observable<BonusOverview[]> {
        return this.apiService.get<BonusOverview[]>( this.bonusUrl + '/bonusoverviews', { season: season, trackNo: trackNo } );
    }

    getBonusResult( bonusQuestionId: number ): Observable<BonusResult> {
        return this.apiService.get<BonusResult>( this.bonusUrl + '/bonusresult', { bonusQuestionId: bonusQuestionId } );
    }

    saveBonusResult( bonusResult: BonusResult ): Observable<BonusResult> {
        return this.apiService.post<BonusResult>( this.bonusUrl + '/bonusresult', bonusResult );
    }

    updateBonusResult( bonusResult: BonusResult ): Observable<BonusResult> {
        return this.apiService.put( this.bonusUrl + '/bonusresult/' + bonusResult.id, bonusResult );
    }
    
    private handleError( error: any ): Observable<any> {
        //        console.error( 'An error occurred', error ); // for demo purposes only
        return throwError( error );
    }

    private handleErrorPromise( error: any ): Promise<any> {
        //        console.error( 'An error occurred', error ); // for demo purposes only
        return Promise.reject( error.message || error );
    }

}
