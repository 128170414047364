<div>
	<season-selector class="col-md-3"></season-selector>
	<track-selector class="col-md-3" closest="true"></track-selector>
</div>
<div class="spinner-container" *ngIf="dataSource.loading$ | async">
    <mat-spinner style="margin: 0 auto;"></mat-spinner>
</div>
<div class="table-container mat-elevation-z8">
	<mat-table #table [dataSource]="dataSource">
		<!-- position Column -->
		<ng-container matColumnDef="position" sticky>
			<mat-header-cell *matHeaderCellDef><span matTooltip="Platz"> Pl. </span></mat-header-cell>
			<mat-cell *matCellDef="let raceOverview">
				{{ raceOverview.position }}
			</mat-cell>
		</ng-container>
		
		<!-- positionDiff Column -->
		<ng-container matColumnDef="positionDiff">
			<mat-header-cell *matHeaderCellDef ><span matTooltip="Trend"> +/- </span></mat-header-cell>
			<mat-cell *matCellDef="let raceOverview"   >
				<div *ngIf="raceOverview.positionDiff > 0" class="green">
					<mat-icon>arrow_drop_up</mat-icon>
					<span class="trend diff">{{ raceOverview.positionDiff }}</span>	
				</div>
				<div *ngIf="raceOverview.positionDiff < 0" class="red">						
					<mat-icon>arrow_drop_down</mat-icon>
					<span class="trend diff">{{ raceOverview.positionDiff }}</span>
				</div>
			</mat-cell>
		</ng-container>
		
		<!-- Name Column -->
		<ng-container matColumnDef="userName" sticky>
			<mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
			<mat-cell *matCellDef="let raceOverview">
			<div *ngIf="(raceOverview.user)">
				{{raceOverview.user.userName}}
			</div> 
			<div *ngIf="(!raceOverview.user)">
				Ergebnis
			</div>
			</mat-cell>
		</ng-container>
		
		<!-- Prediction Columns -->
		<ng-container *ngFor="let pos of [0,1,2,3,4,5,6,7,8,9]" matColumnDef="prediction{{(pos+1)}}">
			<mat-header-cell *matHeaderCellDef class="prediction"><span matTooltip="Platz {{(pos+1)}}"> {{(pos+1)}}. </span></mat-header-cell>
			<mat-cell *matCellDef="let raceOverview" class="prediction">
				<div *ngIf="(raceOverview.predictions && raceOverview.predictions[pos])  && (!mobileQuery.matches)" [class]="(result && raceOverview.user && raceOverview.predictionPoints && raceOverview.predictionPoints[pos] < 1) ? 'lightgray' : ''">
				{{raceOverview.predictions[pos]?.driverName}}
				<span *ngIf="(result && raceOverview.predictionPoints && raceOverview.predictionPoints[pos] > 0)" class="punkte">{{raceOverview.predictionPoints[pos]}}</span>
				</div>
				<div *ngIf="(raceOverview.predictions && raceOverview.predictions[pos])  && (mobileQuery.matches)" [class]="(result && raceOverview.user) ? ((raceOverview.predictionPoints && raceOverview.predictionPoints[pos] > 0) ? 'green' : 'lightgray') : ''">
				{{raceOverview.predictions[pos]?.trigram}}
				<span *ngIf="(result && raceOverview.predictionPoints && raceOverview.predictionPoints[pos] > 0)" class="punkte">{{raceOverview.predictionPoints[pos]}}</span> 
				</div>
				<div *ngIf="(raceOverview.predictions && !raceOverview.predictions[pos])">
				-
				</div>
			</mat-cell>
		</ng-container>
		
	<!-- FastestLap Column -->
		<ng-container matColumnDef="fastestLap">
			<mat-header-cell *matHeaderCellDef class="prediction"><span matTooltip="Schnellste Runde"> S.R. </span></mat-header-cell>
			<mat-cell *matCellDef="let raceOverview" class="prediction">			
				<div *ngIf="(raceOverview.fastestLap && (!mobileQuery.matches))" [class]="(result && raceOverview.user && raceOverview.fastestLapPoints < 1) ? 'lightgray' : ''">
				{{raceOverview.fastestLap?.driverName}}
				<span *ngIf="(result && raceOverview.fastestLapPoints)" class="punkte">{{raceOverview.fastestLapPoints}}</span>
				</div>
				<div *ngIf="(raceOverview.fastestLap && (mobileQuery.matches))" [class]="(result && raceOverview.user) ? ((raceOverview.fastestLapPoints) ? 'green' : 'lightgray') : '' ">
				{{raceOverview.fastestLap?.trigram}}
				<span *ngIf="(result && raceOverview.fastestLapPoints)" class="punkte">{{raceOverview.fastestLapPoints}}</span>
				</div>
				<div *ngIf="(raceOverview.predictions && !raceOverview.fastestLap)">
				-
				</div>
			</mat-cell>
		</ng-container>
		
		<!-- RacePoints Column -->
		<ng-container matColumnDef="racepoints" stickyEnd>
			<mat-header-cell *matHeaderCellDef><span matTooltip="Punkte"> P. </span></mat-header-cell>
			<mat-cell *matCellDef="let raceOverview">
				{{ raceOverview.racePoints }}
			</mat-cell>
		</ng-container>
		
		<!-- TotalBonuspoints Column -->
		<ng-container matColumnDef="totalbonuspoints">
			<mat-header-cell *matHeaderCellDef><span matTooltip="Bonuspunkte"> B. </span></mat-header-cell>
			<mat-cell *matCellDef="let raceOverview">
				{{ raceOverview.bonusPoints }}
			</mat-cell>
		</ng-container>
		
		<!-- Wins Column -->
		<ng-container matColumnDef="totalwins">
			<mat-header-cell *matHeaderCellDef><span matTooltip="Siege"> S. </span></mat-header-cell>
			<mat-cell *matCellDef="let raceOverview">
				{{ raceOverview.totalSeasonWins | number:'1.0-2':'de-DE' }}
			</mat-cell>
		</ng-container>
		
		<!-- TotalPoints Column -->
		<ng-container matColumnDef="totalpoints" stickyEnd>
			<mat-header-cell *matHeaderCellDef><span matTooltip="Gesamtpunkte"> G. </span></mat-header-cell>
			<mat-cell *matCellDef="let raceOverview">
				{{ raceOverview.totalSeasonPoints }}
			</mat-cell>
		</ng-container>
		
		<mat-header-row	*matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
		<mat-row *matRowDef="let row; columns: displayedColumns;"
			[ngClass]="{'result': (!row.user), 'user': (row.user) && row.user.userID == userService.getCurrentUser().userID, 'highlight': row.win > 0}"></mat-row>
	</mat-table>
	<button *ngIf="userService.isAdmin()" class="save-btn btn btn-primary"
		(click)="shareWA()">Share</button>
</div>