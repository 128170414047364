import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from "@angular/forms";
import { Subscription, Observable, BehaviorSubject, of, throwError } from "rxjs";
import { map, flatMap, catchError } from "rxjs/operators";
import { MediaMatcher } from '@angular/cdk/layout';

import { ToasterService, ToasterConfig } from 'angular2-toaster';

import {
    Driver,
    BonusResult,
    BonusQuestion,
    Track
} from '../model';
import {
    DriverService,
    BonusService,
    SelectionService,
    TrackService,
} from "../service";

import { CustomValidators } from "../validators/custom-validators";

@Component( {
    selector: 'bonus-result',
    templateUrl: './bonus-result.component.html',
    styleUrls: ['./bonus-result.component.css']
} )
export class BonusResultComponent implements OnInit, OnDestroy {

    bonusQuestions: BonusQuestion[];
    bonusResults: BonusResult[];
    tracks: Track[];
    drivers: Driver[];
    season: number;
    mobileQuery: MediaQueryList;
    private _mobileQueryListener: () => void;
    myForm: FormGroup;
    bonusResultsFormArray: FormArray;

    private loadingSubject = new BehaviorSubject<boolean>( false );

    public loading$ = this.loadingSubject.asObservable();

    public toasterConfig: ToasterConfig = new ToasterConfig( {
        animation: 'fade',
        positionClass: 'toast-center',
        limit: 1
    } );

    constructor( private fb: FormBuilder, private selectionService: SelectionService, private bonusService: BonusService, media: MediaMatcher, private trackService: TrackService, private driverService: DriverService, private toasterService: ToasterService ) {
        this.bonusResultsFormArray = new FormArray( [] );
        this.myForm = this.fb.group( {
            'bonusResultsFormArr': this.bonusResultsFormArray
        } );
        this.mobileQuery = media.matchMedia( '(max-width: 1200px)' );
        this.mobileQuery.addListener( this._mobileQueryListener );
    }


    ngOnInit() {
        if ( this.season == null ) {
            this.season = new Date().getFullYear();
        }
        this.loadingSubject.next( true );
        this.getDrivers();
        this.getTracks();
        this.getBonusQuestions();
    }

    ngOnDestroy() {
        // prevent memory leak when component destroyed
    }

    getBonusQuestions() {
        this.bonusQuestions = new Array<BonusQuestion>();;
        this.bonusResults = [];
        this.bonusService.getBonusQuestions( this.season ).pipe(
            map(
                bonusQuestions => {
                    this.bonusQuestions = bonusQuestions;
                    this.initFormGroup( bonusQuestions );
                    bonusQuestions.forEach(
                        bonusQuestion => {
                            this.bonusService.getBonusResult( bonusQuestion.bonusId ).pipe(
                                map(
                                    bonusResult => {
                                        this.bonusResults.push( bonusResult );
                                    }
                                ),
                                catchError(( error ) => {
                                    let newBonusResult = new BonusResult();
                                    newBonusResult.season = this.season;
                                    newBonusResult.bonusQuestion = bonusQuestion;
                                    this.bonusResults.push( newBonusResult );
                                    return of( newBonusResult );
                                }
                                )
                            ).subscribe();
                        }
                    );
                }
            )
        ).subscribe(
            result => {
                this.loadingSubject.next( false );
            },
            error => {
                this.loadingSubject.next( false );
            }
            );
    }

    getDrivers(): void {
        this.driverService.getDrivers( this.season ).then( drivers => this.drivers = drivers );
    }

    getTracks(): void {
        this.trackService.getTracks( this.season ).then( tracks => {
            this.tracks = tracks;
        } );
    }

    onSave( bonusResult: BonusResult, resultIndex: number ) {
        this.loadingSubject.next( true );
        this.bonusService.saveBonusResult( bonusResult ).subscribe(
            result => {
                this.bonusResults[resultIndex] = result;
                this.loadingSubject.next( false );
                this.toasterService.pop( 'success', 'Bonusergebnis gespeichert', 'Das Ergebnis für die Bonusfrage ' + bonusResult.bonusQuestion.question + ' wurde erfolgreich gespeichert.' );
            },
            error => {
                this.loadingSubject.next( false );
                this.toasterService.pop( 'error', 'Fehler', 'Das Ergebnis für die Bonusfrage ' + bonusResult.bonusQuestion.question + ' konnte nicht gespeichert werden.' );
            }
        );
    }
    onUpdate( bonusResult: BonusResult, resultIndex: number ) {
        this.loadingSubject.next( true );
        this.bonusService.updateBonusResult( bonusResult ).subscribe(
            result => {
                this.bonusResults[resultIndex] = result;
                this.loadingSubject.next( false );
                this.toasterService.pop( 'success', 'Bonusergebnis gespeichert', 'Das Ergebnis für die Bonusfrage ' + bonusResult.bonusQuestion.question + ' wurde erfolgreich gespeichert.' );
            },
            error => {
                this.loadingSubject.next( false );
                this.toasterService.pop( 'error', 'Fehler', 'Das Ergebnis für die Bonusfrage ' + bonusResult.bonusQuestion.question + ' konnte nicht gespeichert werden.' );
            }
        );
    }

    compareDriver( d1: Driver, d2: Driver ): boolean {
        return d1 && d2 ? d1.id === d2.id : d1 === d2;
    }

    compareTrack( t1: Track, t2: Track ): boolean {
        return t1 && t2 ? t1.trackId === t2.trackId : t1 === t2;
    }

    initFormGroup( bonusQuestions: BonusQuestion[] ) {
        bonusQuestions.forEach(
            bonusQuestion => {
                this.bonusResultsFormArray.push(
                    this.fb.group( {
                        result: new FormControl( '', Validators.required ),
                        track: new FormControl( '', Validators.required )
                    } ) );
            }
        );
    }

}
