import { Driver } from "./driver";
import { User } from "./user";

export class BonusQuestion {
  bonusId: number;
  season: number;
  points: number;
  deadline: Date;
  shortcut: string;
  question: string;
}

