
<form [formGroup]="myForm">
	<div class="spinner-container" *ngIf="loading$ | async">
		<mat-spinner></mat-spinner>
	</div>
	<h4>Bonus Ergebnis</h4>
	<div class="result-inputs">
		<toaster-container [toasterconfig]="toasterConfig"></toaster-container>
		<div *ngIf="(bonusResults)"
			class="d-flex align-content-start"
			formArrayName="bonusResultsFormArr"
		>
		<mat-grid-list cols="1">
			<mat-card *ngFor="let bonusResult of bonusResults; let i = index"
				[formGroupName]="i"
			>
			<mat-card-title>{{bonusResult.bonusQuestion.question}}</mat-card-title>
			<mat-card-content> 
			<mat-form-field
				class="input-field"
			> <mat-select placeholder="Ergebnis" [compareWith]="compareDriver"
				[(ngModel)]="bonusResult.result" formControlName="result"
				id="result"
			> <mat-option *ngFor="let driver of drivers" [value]="driver">{{driver.driverName}}</mat-option>
			</mat-select> </mat-form-field> <mat-form-field class="input-field"> <mat-select
				placeholder="Strecke" [compareWith]="compareTrack"
				[(ngModel)]="bonusResult.track" formControlName="track" id="track"
			> <mat-option *ngFor="let track of tracks" [value]="track">{{track.trackName}}</mat-option>
			</mat-select> </mat-form-field>
			<button *ngIf="!bonusResult.id" class="save-btn btn btn-primary"
				(click)="onSave(bonusResult, i)"
				[disabled]="myForm.controls.bonusResultsFormArr.controls[i].invalid || myForm.controls.bonusResultsFormArr.controls[i].pending || myForm.controls.bonusResultsFormArr.controls[i].disabled"
			>Speichern</button>
			<button *ngIf="bonusResult.id > 0" class="save-btn btn btn-primary"
				(click)="onUpdate(bonusResult, i)"
				[disabled]="myForm.controls.bonusResultsFormArr.controls[i].invalid || myForm.controls.bonusResultsFormArr.controls[i].pending || myForm.controls.bonusResultsFormArr.controls[i].disabled"
			>Ändern</button>
			</mat-card-content> </mat-card>
			</mat-grid-list>
		</div>
	</div>
</form>
