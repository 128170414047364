<div class="app-container" [class.app-container-is-mobile]="mobileQuery.matches">
	<app-header></app-header>
	<mat-sidenav-container class="sidenav-container">
		<mat-sidenav #sidenav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches" 
	  				fixedTopGap="56" [opened]="shouldOpen() && hasSignedIn()">
			<side-nav></side-nav>
		</mat-sidenav>
	  	<mat-sidenav-content>
	  		<div class="sidenav-content">
	  			<router-outlet></router-outlet>
	  		</div>
		</mat-sidenav-content>
	</mat-sidenav-container>
</div>
