import { Component, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';

import { Subscription } from "rxjs";

import { SelectionService } from "../service/selection.service";
import { QualifyingService } from "../service/qualifying.service";
import { Track } from "../model/track";
import { Qualifying } from "../model/qualifying";
import { Driver } from "../model/driver";

@Component( {
    selector: 'qualifying',
    templateUrl: './qualifying.component.html',
    styleUrls: [
        './qualifying.component.css'
    ]
} )
export class QualifyingComponent implements OnDestroy {

    trackSubscription: Subscription;
    qualifying: Qualifying;
    selectedTrack: Track;
    mobileQuery: MediaQueryList;
    private _mobileQueryListener: () => void;

    constructor( private selectionService: SelectionService, private qualifyingService: QualifyingService, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher ) {
        this.mobileQuery = media.matchMedia('(max-width: 600px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
        this.trackSubscription = selectionService.selectedTrack.subscribe(
            track => {
                this.selectedTrack = track;
                this.getQualifying();
            } );
    }

    getQualifying(): void {
        this.qualifyingService.getQualifying( this.selectedTrack.season, this.selectedTrack.trackNo ).subscribe(
            quali => this.qualifying = quali,
            error => this.qualifying = null
            );

    }

    ngOnDestroy() {
        // prevent memory leak when component destroyed
        this.trackSubscription.unsubscribe();
    }

}
