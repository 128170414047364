import { FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import {
    BonusPrediction,
    Driver,
    Prediction,
    Track
} from "../model";

export class CustomValidators {

    static track( fc: FormControl ): ValidationErrors {
        const track = <Track>fc.value;
        let isValid = CustomValidators.isTrackValid(track);
        const message = {
            "track": {
                "message": "Das Rennen ist bereits vorbei."
            }
        }
        return isValid ? null : message;
    }
    
    static isTrackValid(track: Track): boolean {
        let isValid = track != null;
        if ( isValid ) {
            const currentTime = new Date().getTime();
            const startTime = new Date( track.startTime ).getTime();
            isValid = ( track ) && startTime > currentTime;
        }
        return isValid;
    }

    static prediction( form: FormGroup ): ValidationErrors {
        const predictions = <FormArray>form.get( "predictions" );
        const predictionsValues = <Array<Driver>>predictions.value;
        const duplicateDrivers = <Array<number>>CustomValidators.findDuplicate( predictionsValues );
        let doubleDriverNames = "";
        duplicateDrivers.forEach(( driverId, index ) => {
            if ( driverId ) {
                let driver = predictionsValues.find( driver => driver && driver.id == driverId );
//                console.info( "driver: " + driver.driverName + " index: " + index )
                doubleDriverNames = doubleDriverNames + "[" + driver.driverName + "]";
            }
        } );
        const isValid = form.valid && duplicateDrivers.length == 0;
        const message = {
            "prediction": {
                "message": "Mindestens ein Fahrer ist doppelt: " + doubleDriverNames
            }
        }
        return isValid ? null : message;
    }
    
    static result( form: FormGroup ): ValidationErrors {
        const results = <FormArray>form.get( "results" );
        const resultsValues = <Array<Driver>>results.value;
        const duplicateDrivers = <Array<number>>CustomValidators.findDuplicate( resultsValues );
        let doubleDriverNames = "";
        duplicateDrivers.forEach(( driverId, index ) => {
            if ( driverId ) {
                let driver = resultsValues.find( driver => driver && driver.id == driverId );
//                console.info( "driver: " + driver.driverName + " index: " + index )
                doubleDriverNames = doubleDriverNames + "[" + driver.driverName + "]";
            }
        } );
        const isValid = form.valid && duplicateDrivers.length == 0;
        const message = {
            "result": {
                "message": "Mindestens ein Fahrer ist doppelt: " + doubleDriverNames
            }
        }
        return isValid ? null : message;
    }

    static filterEmptyDriver( driver: Driver ): boolean {
        if ( ( driver ) && !isNaN( driver.id ) ) {
            return true;
        } else {
            return false;
        }
    }

    static findDuplicate( arr ): Array<number> {
        let driverIds = new Array<number>();
        arr.forEach(( driver, index ) => {
            if ( driver ) {
                driverIds.push( driver.id );
            }
        } );
        const result = driverIds.filter(( value, index, array ) =>
            ( value ) && array.indexOf( value ) !== index );
        return result;
    }

    static bonusPrediction( fc: FormControl ): ValidationErrors {
        return CustomValidators.isBonusPredictionValid( fc.value );
    }

    static isBonusPredictionValid( bonusPrediction: BonusPrediction ): ValidationErrors {
        let isValid = ( ( bonusPrediction !== undefined ) && ( bonusPrediction.bonusQuestion !== undefined ) && ( bonusPrediction.bonusQuestion.deadline !== undefined ) );
        if ( isValid ) {
            const currentTime = new Date().getTime();
            const deadline = new Date( bonusPrediction.bonusQuestion.deadline ).getTime();
            isValid = ( isValid ) && ( deadline > currentTime );
        }
        const message = {
            "bonusPrediction": {
                "message": "Tipptermin liegt in der Vergangenheit."
            }
        }
        return isValid ? null : message;
    }
    
    static qualifying( form: FormGroup ): ValidationErrors {
        const qualifyingPositions = <FormArray>form.get( "positions" );
        const qualifyingPositionsValues = <Array<Driver>>qualifyingPositions.value;
        const duplicateDrivers = <Array<number>>CustomValidators.findDuplicate( qualifyingPositionsValues );
        let doubleDriverNames = "";
        duplicateDrivers.forEach(( driverId, index ) => {
            if ( driverId ) {
                let driver = qualifyingPositionsValues.find( driver => driver && driver.id == driverId );
//                console.info( "driver: " + driver.driverName + " index: " + index )
                doubleDriverNames = doubleDriverNames + "[" + driver.driverName + "]";
            }
        } );
        const isValid = form.valid && duplicateDrivers.length == 0;
        const message = {
            "qualifying": {
                "message": "Mindestens ein Fahrer ist doppelt: " + doubleDriverNames
            }
        }
        return isValid ? null : message;
    }
    
    
}
