<mat-nav-list *ngIf="hasSignedIn()">
	<a *ngIf="hasSignedIn()" mat-list-item routerLink="/tippabgabe" (click)="!shouldOpen() ? sidenavService.toggle.emit() : ''">Tippabgabe</a>
	<a *ngIf="hasSignedIn()" mat-list-item routerLink="/tippabgabe-bonus" (click)="!shouldOpen() ? sidenavService.toggle.emit() : ''">Bonus</a>
	<a *ngIf="hasSignedIn()" mat-list-item routerLink="/tippuebersicht" (click)="!shouldOpen() ? sidenavService.toggle.emit() : ''">Tippübersicht</a>
	<a *ngIf="hasSignedIn()" mat-list-item routerLink="/liveuebersicht" (click)="!shouldOpen() ? sidenavService.toggle.emit() : ''">Liveübersicht</a>
	<a *ngIf="hasSignedIn()" mat-list-item routerLink="/bonusuebersicht" (click)="!shouldOpen() ? sidenavService.toggle.emit() : ''">Bonusübersicht</a>
	<a *ngIf="isAdmin()" mat-list-item routerLink="/qualifying" (click)="!shouldOpen() ? sidenavService.toggle.emit() : ''">Quali</a>
	<a *ngIf="isAdmin()" mat-list-item routerLink="/ergebnis" (click)="!shouldOpen() ? sidenavService.toggle.emit() : ''">Ergebnis</a>
	<a *ngIf="isAdmin()" mat-list-item routerLink="/bonusergebnis" (click)="!shouldOpen() ? sidenavService.toggle.emit() : ''">Bonusergebnis</a>
	<a *ngIf="isAdmin()" mat-list-item routerLink="/tippnachtrag" (click)="!shouldOpen() ? sidenavService.toggle.emit() : ''">Tippnachtrag</a>
</mat-nav-list>
