import { BonusQuestion } from "./bonusquestion";
import { Driver } from "./driver";
import { Track } from "./track";

export class BonusResult {
  id: number;
  season: number;
  result: Driver;
  track: Track;
  bonusQuestion: BonusQuestion;
}

