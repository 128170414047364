import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from '../service';
import { User } from "../model/user";
import { Observable, Subscription } from 'rxjs';

@Injectable()
export class GuestGuard implements CanActivate {

    //    private user: User;
    //    private userSubscription: Subscription;

    constructor( private router: Router, private userService: UserService ) {
        //        this.userSubscription = userService.currentUser.subscribe(
        //            user => {
        //                this.user = user;
        //            } );
    }

    canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): boolean {
        if ( this.userService.getCurrentUser() && !(state.url === "/login") ) {
            this.router.navigate( ['/'] );
            return false;
        } else {
            this.userService.setCurrentUser( null );
            return true;
        }
    }
}
