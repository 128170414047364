import { Track } from "./track";
import { Driver } from "./driver";
import { User } from "./user";
import { BonusPrediction } from "./bonusprediction";
import { BonusPoints } from "./bonuspoints";

export class BonusOverview {
    season: number;
    track: Track;
    user: User;
    position: number;
    positionDiff: number;
    bonusPredictions: BonusPrediction[];
    bonusPredictionPoints: BonusPoints[];
    bonusPoints: number;
    totalSeasonWins: number;
    totalSeasonPoints: number;
}