import { Track } from "./track";
import { Driver } from "./driver";
import { User } from "./user";

export class RaceOverview {
    season: number;
    track: Track;
    user: User;
    position: number;
    positionDiff: number;
    predictions: Driver[];
    fastestLap: Driver;
    predictionPoints: number[];
    fastestLapPoints: number;
    racePoints: number;
    bonusPoints: number;
    win: number;
    totalSeasonWins: number;
    totalSeasonPoints: number;
}
