import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from "@angular/forms";
import { flatMap, map } from "rxjs/operators";
import { Observable, BehaviorSubject, of } from "rxjs";
import { MediaMatcher } from '@angular/cdk/layout';

import { ToasterService, ToasterConfig } from 'angular2-toaster';

import {
    Driver,
    Result,
    User,
    Track
} from '../model';
import {
    DriverService,
    QualifyingService,
    RaceOverviewService,
    ResultService,
    TrackService,
    UserService
} from "../service";

import { CustomValidators } from "../validators/custom-validators";
import { RaceOverviewDataSource } from "../race-overview/race-overview.datasource";

@Component( {
    selector: 'live-overview',
    templateUrl: './live-overview.component.html',
    styleUrls: ['./live-overview.component.css']
} )

export class LiveOverviewComponent implements OnInit, OnDestroy {

    displayedColumns = ['position', 'positionDiff', 'userName', 'prediction1', 'prediction2', 'prediction3', 'prediction4', 'prediction5', 'prediction6', 'prediction7', 'prediction8', 'prediction9', 'prediction10', 'fastestLap', 'racepoints', 'totalbonuspoints', 'totalwins', 'totalpoints'];
    dataSource: RaceOverviewDataSource;
    liveTrack: Track;
    result: Result;
    drivers: Driver[];
    currentUser: User;
    mobileQuery: MediaQueryList;
    private _mobileQueryListener: () => void;
    myForm: FormGroup;

    private loadingSubject = new BehaviorSubject<boolean>( false );

    public loading$ = this.loadingSubject.asObservable();

    public toasterConfig: ToasterConfig = new ToasterConfig( {
        animation: 'fade',
        positionClass: 'toast-center',
        limit: 1
    } );

    constructor( private fb: FormBuilder, private raceOverviewService: RaceOverviewService, private resultService: ResultService, public userService: UserService, media: MediaMatcher, private trackService: TrackService, private qualifyingService: QualifyingService, private driverService: DriverService, private toasterService: ToasterService ) {
        this.mobileQuery = media.matchMedia( '(max-width: 1200px)' );
        this.mobileQuery.addListener( this._mobileQueryListener );

    }

    ngOnInit(): void {
        this.myForm = this.fb.group( {
            'result': new FormGroup( {
                'results': new FormArray( [new FormControl( '', Validators.required ), new FormControl( '', Validators.required ), new FormControl( '', Validators.required ), new FormControl( '', Validators.required ), new FormControl( '', Validators.required ), new FormControl( '', Validators.required ), new FormControl( '', Validators.required ), new FormControl( '', Validators.required ), new FormControl( '', Validators.required ), new FormControl( '', Validators.required )] ),
                'fastestLap': new FormControl( '', Validators.required )
            }, CustomValidators.result )
        } );
        this.loadingSubject.next( true );
        this.dataSource = new RaceOverviewDataSource( this.raceOverviewService, this.resultService );
        this.trackService.getLiveTrack().pipe(
            flatMap(
                track => {
                    if ( ( track ) && ( this.liveTrack ) && ( track.trackId == this.liveTrack.trackId ) ) {
                    } else {
                        this.liveTrack = track;
                        this.result = new Result();
                        this.result.track = track;
                        this.result.season = track.season;
                        this.dataSource.getRaceOverviews( this.liveTrack.season, this.liveTrack );
                        this.getDrivers( this.liveTrack.season );
                        this.isLiveTrack();
                        return this.resultService.getResult( this.liveTrack.season, this.liveTrack.trackNo ).pipe(
                            map(
                                result => {
                                    this.result = result;
                                    this.myForm.disable();
                                    this.loadingSubject.next( false );
                                }
                            ) );
                    }
                },
                error => {
                    //                    console.log( error );
                    //                    console.log( "no result and no quali found..." );
                    //                    console.log( this.result );
                    //TODO: was machen wir im Fehlerfall???
                }
            ) ).subscribe(
            () => { },
            error => {
                return this.setResultWithQuali( this.liveTrack );
            }
            );

    }

    ngOnDestroy() {
        // prevent memory leak when component destroyed
    }

    getDrivers( season ): void {
        this.driverService.getDrivers( season ).then( drivers => this.drivers = drivers );
    }

    setResultWithQuali( track ): Observable<Result> {
        this.qualifyingService.getQualifying( track.season, track.trackNo ).subscribe(
            quali => {
                this.result = new Result();
                this.result.track = track;
                this.result.season = track.season;
                this.result.positions = quali.positions.slice( 0, 10 );
                this.result.fastestLap = quali.positions[0];
                this.loadingSubject.next( false );
            }, error => {
                this.result = new Result();
                this.result.track = track;
                this.result.season = track.season;
                this.loadingSubject.next( false );
            } );
        return of( this.result );
    }

    onSimulate() {
        this.dataSource.getLiveOverviews( this.result );
    }

    onSave() {
        this.loadingSubject.next( true );
        if ( this.result.id > 0 ) {
            this.resultService.updateResult( this.result ).subscribe(
                result => {
                    this.result = result;
                    this.dataSource.getRaceOverviews( this.result.season, this.result.track );
                    this.loadingSubject.next( false );
                    this.toasterService.pop( 'success', 'Ergebnis gespeichert', 'Das Ergebnis für das Rennen ' + this.result.track.trackName + ' wurde erfolgreich gespeichert.' );
                },
                error => {
                    this.toasterService.pop( 'error', 'Fehler', 'Das Ergebnis für das Rennen ' + this.result.track.trackName + ' konnte nicht gespeichert werden.' );
                }
            );
        } else {
            this.resultService.saveResult( this.result ).subscribe(
                result => {
                    this.result = result;
                    this.dataSource.getRaceOverviews( this.result.season, this.result.track );
                    this.loadingSubject.next( false );
                    this.toasterService.pop( 'success', 'Ergebnis gespeichert', 'Das Ergebnis für das Rennen ' + this.result.track.trackName + ' wurde erfolgreich gespeichert.' );
                },
                error => {
                    this.toasterService.pop( 'error', 'Fehler', 'Das Ergebnis für das Rennen ' + this.result.track.trackName + ' konnte nicht gespeichert werden.' );
                }
            );
        }
    }

    compareDriver( d1: Driver, d2: Driver ): boolean {
        return d1 && d2 ? d1.id === d2.id : d1 === d2;
    }

    isLiveTrack(): boolean {
        const currentTime = new Date().getTime();
        const startTime = new Date( this.liveTrack.startTime ).getTime();
        if ( ( this.liveTrack ) && startTime < currentTime ) {
            return true;
        } else {
            this.myForm.disable();
            return false;
        }
    }

}
