import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Subscription } from "rxjs";
import { MediaMatcher } from '@angular/cdk/layout';

import { MatTableDataSource } from '@angular/material/table';


import {
    BonusQuestion,
    Track,
    BonusOverview,
    BonusResult
} from '../model';
import {
    BonusService,
    SelectionService,
    TrackService,
    UserService
} from "../service";

import { BonusOverviewDataSource } from "./bonus-overview.datasource";

@Component( {
    selector: 'app-bonus-overview',
    templateUrl: './bonus-overview.component.html',
    styleUrls: ['./bonus-overview.component.css']
} )
export class BonusOverviewComponent implements OnInit, OnDestroy {

    @Input() season: number;
    seasonSubscription: Subscription;
    displayedColumns: string[];
    columns: any[] = [];
    posColumns: any[] = [
        { columnDef: 'position', header: 'Pl.', tooltip: 'Platz', dataName: row => `${row.position}`, sticky: 'sticky' },
        { columnDef: 'positionDiff', header: '+/-', tooltip: 'Trend', dataName: row => `${row.positionDiff}` },
        { columnDef: 'userName', header: 'Name', tooltip: 'Name', dataName: row => `${row.user.userName}`, sticky: 'sticky' }
    ];
    pointsColumns: any[] = [
        { columnDef: 'bonuspoints', header: 'B.', tooltip: 'Bonuspunkte', sticky: 'stickyEnd', dataName: row => `${row.bonusPoints}` },
        { columnDef: 'totalwins', header: 'S.', tooltip: 'Siege', dataName: row => `${row.totalSeasonWins}` },
        { columnDef: 'totalpoints', header: 'G.', tooltip: 'Gesamtpunkte', sticky: 'stickyEnd', dataName: row => `${row.totalSeasonPoints}` }
    ];
    displayedBonusQuestionColumns: string[];
    bonusQuestionsColumns: any[] = [
        { columnDef: 'deadline', header: 'Termin', tooltip: 'Termin', dataName: row => `${new DatePipe( 'de-DE' ).transform( row.deadline, 'dd. MMMM y, HH:mm' )}`, shortData: row => `${new DatePipe( 'de-DE' ).transform( row.deadline, 'short' )}` },
        { columnDef: 'question', header: 'Bonusfrage', tooltip: 'Bonusfrage', dataName: row => `${row.question}` },
        { columnDef: 'shortcut', header: 'Abk.', tooltip: 'Abkürzung', dataName: row => `${row.shortcut}` },
        { columnDef: 'result', header: 'Ergebnis', tooltip: 'Ergebnis', dataName: 'result' },
        { columnDef: 'points', header: 'Punkte', tooltip: 'Punkteregel', dataName: row => `${row.points}` }
    ];
    bonusQuestions: BonusQuestion[];
    bonusResults: BonusResult[];
    dataSource: BonusOverviewDataSource;
    currentTrack: Track;
    mobileQuery: MediaQueryList;
    private _mobileQueryListener: ( event ) => void;

    constructor( private selectionService: SelectionService, private trackService: TrackService, private bonusService: BonusService, media: MediaMatcher, public userService: UserService ) {
        this.mobileQuery = media.matchMedia( '(max-width: 1200px)' );
        this.mobileQuery.addListener( this._mobileQueryListener );
        this.seasonSubscription = selectionService.selectedSeason.subscribe(
            season => {
                this.season = season;
                this.loadColumnsWithBonusQuestions( season );
                if ( season == new Date().getFullYear() ) {
                    this.getLiveTrack().subscribe(
                        track => {
                            this.currentTrack = track;
                            if ( this.bonusQuestions.length > 0 ) {
                                this.dataSource.getBonusOverviews( track.season, track );
                            }
                        } );
                } else {
                    this.getLastTrack( season ).subscribe(
                        track => {
                            this.currentTrack = track;
                            if ( this.bonusQuestions.length > 0 ) {
                                this.dataSource.getBonusOverviews( track.season, track );
                            }
                        } );
                }
            } );
        this.displayedBonusQuestionColumns = this.bonusQuestionsColumns.map( x => x.columnDef );
    }

    ngOnInit() {
        if ( this.season == null ) {
            this.season = new Date().getFullYear();
        }
        this.dataSource = new BonusOverviewDataSource( this.bonusService );
        this.loadColumnsWithBonusQuestions( this.season );
        this.getLiveTrack().subscribe(
            track => {
                this.currentTrack = track;
                this.dataSource.getBonusOverviews( track.season, track );
            } );
    }

    ngOnDestroy() {
        // prevent memory leak when component destroyed
        this.seasonSubscription.unsubscribe();
        this.mobileQuery.removeListener( this._mobileQueryListener );
        this.mobileQuery = null;
    }

    getLiveTrack() {
        return this.trackService.getLiveTrack();
    }

    getLastTrack( season: number ) {
        return this.trackService.getLastTrack( season );
    }

    getBonusQuestions( season: number ) {
        return this.bonusService.getBonusQuestions( season );
    }

    getBonusResult( bonusQuestionId: number ) {
        return this.bonusService.getBonusResult( bonusQuestionId );
    }

    loadColumnsWithBonusQuestions( season: number ) {
        this.getBonusQuestions( season ).subscribe(
            bonusQuestions => {
                this.bonusQuestions = bonusQuestions;
                this.bonusResults = [];
                this.columns = [];
                this.columns = this.columns.concat( this.posColumns );
                bonusQuestions.forEach(
                    ( bonusQuestion, index ) => {
                        let columnDef = { columnDef: 'prediction_' + bonusQuestion.shortcut, header: bonusQuestion.shortcut, tooltip: bonusQuestion.question, sticky: '', dataName: index };
                        this.columns.push( columnDef );

                        this.getBonusResult( bonusQuestion.bonusId ).subscribe(
                            result => {
                                this.bonusResults.push( result );
                            }
                        );

                    }
                );
                this.columns = this.columns.concat( this.pointsColumns );
                this.displayedColumns = this.columns.map( x => x.columnDef );
            }
        );
    }

}
