import { Injectable } from '@angular/core';



import { ApiService } from './api.service';

import { Qualifying } from "../model/qualifying";
import { Observable } from "rxjs";

@Injectable()
export class QualifyingService {
    private qualifyingsUrl = 'api/qualifyings';  // URL to web api

    constructor( private apiService: ApiService ) { }

    getQualifyingById( id: number ): Promise<Qualifying> {

        return this.apiService.get<Qualifying>( this.qualifyingsUrl + "/" + id )
            .toPromise();
    }

    getQualifying( season: number, trackNo: number ): Observable<Qualifying> {

        return this.apiService.get<Qualifying>( this.qualifyingsUrl, { season: season.toString(), trackNo: trackNo.toString() } );
    }

    getDriverCount( season: number ): Observable<number> {
        return this.apiService.get<number>( this.qualifyingsUrl + "/driverCount/" + season );
    }

    saveQualifying( qualifying: Qualifying ): Observable<Qualifying> {
        return this.apiService.post<Qualifying>( this.qualifyingsUrl, JSON.stringify( qualifying ) );
    }

}
