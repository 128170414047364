import { Component, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';

import { SidenavService } from './component/side-nav/side-nav.service';

import { UserService } from './service';

import { MatSidenav } from '@angular/material/sidenav';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: [
		'./app.component.css'
	]
})
export class AppComponent {

	title = 'F1 ASD';

	@ViewChild(MatSidenav) sidenav: MatSidenav;
	mobileQuery: MediaQueryList;
	private _mobileQueryListener: () => void;
	desktopQuery: MediaQueryList;
	private _desktopQueryListener: () => void;

	constructor(private sidenavService: SidenavService, private userService: UserService, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private router: Router) {
		this.mobileQuery = media.matchMedia('(max-width: 600px)');
		this._mobileQueryListener = () => changeDetectorRef.detectChanges();
		this.mobileQuery.addListener(this._mobileQueryListener);

		this.desktopQuery = media.matchMedia('(min-width: 1200px)');
		this._desktopQueryListener = () => changeDetectorRef.detectChanges();
		this.desktopQuery.addListener(this._desktopQueryListener);

		this.sidenavService.toggle
			.asObservable()
			.subscribe(
				() => this.sidenav.toggle()
			);
	}

	ngOnDestroy(): void {
		this.mobileQuery.removeListener(this._mobileQueryListener);
	}

	shouldOpen() {
		if (this.desktopQuery.matches) {
			return true;
		} else {
			if (this.router.url === "/tippuebersicht" || this.router.url === "/liveuebersicht") {
				return false;
			} else {
				return !this.mobileQuery.matches;
			}
		}
	}

	hasSignedIn() {
		return !!this.userService.getCurrentUser();
	}

	ngAfterViewInit() {
	}

}
