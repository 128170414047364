import { Injectable } from '@angular/core';
//import { CookieService } from 'ngx-cookie';
import { ApiService } from './api.service';
import { ConfigService } from './config.service';
import { User } from "../model/user";
import { Subject, Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class UserService {

    private currentUser: User;
    private refreshAuthTokenDate: Date;
    private refreshPending = false;

    constructor(
        private apiService: ApiService,
        private config: ConfigService,
    ) { }

    initUserFromLogin( expires_in: number ) {
        this.getUserInfo().toPromise().then( user => {
            user.refreshAuthTokenDate = this.getRefreshAuthTokenDate( expires_in );
            this.refreshAuthTokenDate = user.refreshAuthTokenDate;
            this.currentUser = user;
        } );
    }

    initUser() {
        const promise = this.apiService.get( this.config.refresh_token_url ).toPromise()
            .then( res => {
                if ( res.access_token !== null ) {
                    return this.getUserInfo().toPromise()
                        .then( user => {
                            user.refreshAuthTokenDate = this.getRefreshAuthTokenDate( res.expires_in );
                            this.refreshAuthTokenDate = user.refreshAuthTokenDate;
                            this.currentUser = user;
                            return user;
                        } );
                }
            } )
            .catch(() => null );
        return promise;
    }

    resetCredentials() {
        return this.apiService.get( this.config.reset_credentials_url );
    }

    getUserInfo(): Observable<User> {
        return this.apiService.get<User>( this.config.whoami_url ).pipe(
            map( user => {
                user.refreshAuthTokenDate = this.refreshAuthTokenDate;
                this.currentUser = user;
                return user;
            } ) );
    }

    getCurrentUser(): User {
        if ( this.currentUser ) {
            if ( this.currentUser.refreshAuthTokenDate < new Date() && this.refreshPending == false ) {
                this.refreshPending = true;
                this.initUser().then( usr => {
                    this.refreshPending = false;
                } );
            }
        }
        return this.currentUser;
    }

    setCurrentUser( user: User ) {
        this.currentUser = user;
    }

    getAll() {
        return this.apiService.get( this.config.users_url );
    }

    getAllActive() {
        return this.apiService.get( this.config.users_active_url );
    }

    getRefreshAuthTokenDate( expires_in: number ): Date {
        const currentDateInMs = new Date().getTime();
        const exp_inDateInMs: number = expires_in * 1000;
        const expirationDateInMs: number = currentDateInMs + exp_inDateInMs - ( 30 * 60 * 1000 );
        return new Date( expirationDateInMs );
    }

    isAdmin(): boolean {
        return JSON.stringify( this.getCurrentUser().userRoles ).search( 'Admin' ) !== -1;
    }

}
