import { Component, OnInit, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl } from "@angular/forms";

import { Subscription } from "rxjs";

import { Track } from '../model/track';
import { TrackService } from '../service/track.service';
import { SelectionService } from "../service/selection.service";
import { CustomValidators } from "../validators/custom-validators";

@Component( {
    selector: 'track-selector',
    templateUrl: './track-selector.component.html',
    styleUrls: ['./track-selector.component.css'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TrackSelectorComponent ), multi: true },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => TrackSelectorComponent ), multi: true }
    ]
} )

export class TrackSelectorComponent implements OnInit, ControlValueAccessor {


    tracks: Track[];
    _selectedTracks: Track;
    season: number;
    seasonSubscription: Subscription;
    propagateChange: any = () => { };
    @Input( 'closest' ) closest: string;

    constructor( private trackService: TrackService, private selectionService: SelectionService ) {
        this.seasonSubscription = selectionService.selectedSeason.subscribe(
            season => {
                if ( ( season ) && ( this.season ) && season == this.season && ( this.tracks ) ) {
                    //console.log( "same season nothing to do..." );
                } else {
                    //console.log("season changed in trackservice...getTracks&getNext...");
                    this.season = season;
                    this.getTracks();
                }
            } );
    }

    writeValue( obj: any ): void {
        //console.log( "writeValue:" );
        //console.log( obj );
        if ( obj ) {
            this._selectedTracks = obj;
        }
    }

    registerOnChange( fn: any ): void {
        this.propagateChange = fn;
    }
    registerOnTouched( fn: any ): void {
    }
    setDisabledState( isDisabled: boolean ): void {
    }


    getTracks(): void {
        this.trackService.getTracks( this.season ).then( tracks => {
            this.tracks = tracks;
            this.getNextTrack();
        } );
    }

    getNextTrack(): void {
        if ( this.season < new Date().getFullYear() ) {
            this._selectedTracks = this.tracks[0];
            this.selectionService.setSelectedTrack( this.tracks[0] );
            this.propagateChange( this.tracks[0] );
        } else {
            if ( this.closest == "true" ) {
                let index = this.findNearestDate( this.tracks );
                this._selectedTracks = this.tracks[index];
                this.selectionService.setSelectedTrack( this.tracks[index] );
                this.propagateChange( this.tracks[index] );
            } else {
                this.trackService.getNextTrack().then( selectedTracks => {
                    this._selectedTracks = selectedTracks;
                    this.selectionService.setSelectedTrack( selectedTracks );
                    this.propagateChange( selectedTracks );
                } );
            }
        }
    }

    ngOnInit(): void {
        if ( this.season == null ) {
            this.season = new Date().getFullYear();
        }
        this.selectionService.setSelectedSeason( this.season );
    }

    ngOnDestroy() {
        // prevent memory leak when component destroyed
        this.seasonSubscription.unsubscribe();
    }

    onChange(): void {
        //console.log( "onchange in track selector..." );

        this.selectionService.setSelectedTrack( this._selectedTracks );
        this.propagateChange( this._selectedTracks );
    }

    validate( c: FormControl ) {
        return CustomValidators.track( c );
    }

    compareFn( t1: Track, t2: Track ): boolean {
        return t1 && t2 ? t1.trackId === t2.trackId : t1 === t2;
    }

    findNearestDate( tracks: Track[] ) {

        let now = Date.now()
        let nearest = Infinity
        let winner = -1

        tracks.forEach( function( track, index ) {
            let date = new Date( track.startTime ).getTime();

            let distance = Math.abs( date - now )
            if ( distance < nearest ) {
                nearest = distance
                winner = index
            }
        } )
        return winner
    }
}
