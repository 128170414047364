
<form [formGroup]="myForm">
	<div class="form-group">
		<toaster-container [toasterconfig]="toasterConfig"></toaster-container>
		<div class="spinner-container" *ngIf="loading$ | async">
			<mat-spinner style="margin: 0 auto;"></mat-spinner>
		</div>
		<track-selector></track-selector>
		<br />
		<h4>Qualifying Ergebnis</h4>
		<div formGroupName="qualifying" class="form-group">
			<div *ngIf="(qualifyingResult) && (qualifyingResult.positions)"
				class="d-flex align-content-start flex-wrap"
				formArrayName="positions"
			>
				<div *ngFor="let qualifyingKey of qualifyingKeys; let i = index"
					class="input-field"
				>
					<mat-form-field> <mat-select
						placeholder="Platz {{i+1}}" [compareWith]="compareDriver"
						[(ngModel)]="qualifyingResult.positions[i]"
						formControlName="{{i}}" id="pos{{i}}"
					> <mat-option *ngFor="let driver of drivers" [value]="driver">{{driver.driverName}}</mat-option>
					</mat-select> </mat-form-field>
				</div>
			</div>
		</div>
		<div *ngIf="myForm.get('qualifying').errors"
			class="alert alert-danger"
		>Fehler: {{myForm.get("qualifying").errors?.qualifying.message}}</div>
		<div>
			<button class="btn btn-primary" style="max-width: none;"
				(click)="onSave()"
				[disabled]="myForm.disabled || myForm.invalid || myForm.pending"
			>Startaufstellung speichern</button>
		</div>
	</div>
</form>
