import { DataSource } from '@angular/cdk/collections';
import { Observable, Subscription, BehaviorSubject, of } from 'rxjs';
import { catchError, flatMap, finalize } from "rxjs/operators";

import {
    Driver,
    BonusOverview,
    Result,
    Track,
    User
} from '../model';
import {
    BonusService,
    SelectionService,
} from "../service";

export class BonusOverviewDataSource extends DataSource<BonusOverview>{

    private bonusOverviewsSubject = new BehaviorSubject<BonusOverview[]>( [] );

    private loadingSubject = new BehaviorSubject<boolean>( false );

    public loading$ = this.loadingSubject.asObservable();

    constructor( private bonusService: BonusService ) {
        super();
    }

    connect(): Observable<BonusOverview[]> {
        return this.bonusOverviewsSubject.asObservable();
    }

    disconnect() {
        //        this.bonusOverviewsSubject.complete();
        this.loadingSubject.complete();
    }

    getBonusOverviews( season, track ) {
        this.loadingSubject.next( true );
        this.bonusService.getBonusOverviews( season, track.trackNo ).subscribe(
            bonusOverviews => {
                this.bonusOverviewsSubject.next( bonusOverviews ),
                    this.loadingSubject.next( false );
            },
            error => {
                of( [] );
                this.loadingSubject.next( false );
            }
        );
    }

}

