import { Driver } from "./driver";
import { User } from "./user";
import { BonusQuestion } from "./bonusquestion";

export class BonusPrediction {
  id: number;
  user: User;
  bonusPredictionDriver: Driver;
  bonusQuestion: BonusQuestion;
}

