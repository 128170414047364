import { Component, OnInit, Input, OnDestroy, forwardRef, Output, EventEmitter } from '@angular/core';
import { ToasterService, ToasterConfig, Toast } from 'angular2-toaster';
import { Subscription } from "rxjs";

import { MatTableDataSource } from '@angular/material/table';

import { CustomValidators } from "../../validators/custom-validators";
import {
    BonusPrediction,
    Driver,
} from '../../model';
import {
    BonusService,
    DriverService,
    SelectionService,
    UserService
} from "../../service";

import { BonusQuestionDataSource } from "./bonus-question.datasource";

@Component( {
    selector: 'bonus-prediction',
    templateUrl: './bonus-prediction.component.html',
    styleUrls: ['./bonus-prediction.component.css']
} )

export class BonusPredictionComponent implements OnInit, OnDestroy {

    drivers: Driver[];
    @Input() season: number;
    seasonSubscription: Subscription;
    displayedColumns = ['deadline', 'bonusquestion', 'bonuspoints', 'prediction'];
    dataSource: BonusQuestionDataSource;
    
    public toasterConfig: ToasterConfig = new ToasterConfig( {
        animation: 'fade',
        positionClass: 'toast-center',
        limit: 1
    } );


    constructor( private driverService: DriverService, private selectionService: SelectionService, private bonusService: BonusService, private toasterService: ToasterService, private userService: UserService ) {
        this.seasonSubscription = selectionService.selectedSeason.subscribe(
            season => {
                this.season = season;
                this.dataSource.getBonusPredictions(season);
            } );
    }

    getDrivers(): void {
        this.driverService.getDrivers( this.season ).then( drivers => this.drivers = drivers );
    }

    ngOnInit(): void {
        if ( this.season == null ) {
            this.season = new Date().getFullYear();
        }
        this.getDrivers();
        this.dataSource = new BonusQuestionDataSource( this.bonusService, this.selectionService, this.userService );
        this.dataSource.getBonusPredictions(this.season);
    }

    ngOnDestroy() {
        // prevent memory leak when component destroyed
        this.seasonSubscription.unsubscribe();
        this.dataSource.disconnect();
    }

    onChangePrediction( prediction: BonusPrediction ): void {
        if ( this.isPredictionValid( prediction ) && ( prediction.bonusPredictionDriver != null ) ) {
            this.bonusService.saveBonusPrediction( prediction ).subscribe(
                bonusPrediction => {
                    this.dataSource.getBonusPredictions(this.season);
                    this.toasterService.pop( 'success', 'Tipp gespeichert', 'Der Bonustipp ' + prediction.bonusPredictionDriver.driverName + ' wurde erfolgreich gespeichert.' );
                },
                error => {
                    this.dataSource.getBonusPredictions(this.season);
                    this.toasterService.pop( 'error', 'Fehler', 'Der Bonustipp ' + prediction.bonusPredictionDriver.driverName + ' konnte nicht gespeichert werden.' );
                }
            );
        }
    }

    isPredictionValid( prediction: BonusPrediction ): boolean {
        return CustomValidators.isBonusPredictionValid( prediction ) == null;
    }

    compareDriver( d1: Driver, d2: Driver ): boolean {
        return d1 && d2 ? d1.id === d2.id : d1 === d2;
    }

}
