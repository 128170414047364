import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CalendarComponent, PageNotFoundComponent } from './component';
import { PredictionComponent, BonusPredictionComponent, PredictionAdminComponent } from './prediction';
import { QualifyingResultComponent } from './qualifying-result';
import { ChangePasswordComponent } from "./change-password";
import { RaceOverviewComponent } from './race-overview';
import { LiveOverviewComponent } from './live-overview';
import { ResultComponent } from './result';
import { BonusResultComponent } from './bonus-result';
import { BonusOverviewComponent } from './bonus-overview';

import { LoginComponent } from './login';
import { AdminGuard, LoginGuard, GuestGuard } from './guard';

const appRoutes: Routes = [
    {
        path: 'termine',
        component: CalendarComponent
    },
    {
        path: 'tippabgabe',
        component: PredictionComponent,
        canActivate: [LoginGuard]
    },
    {
        path: 'tippabgabe-bonus',
        component: BonusPredictionComponent,
        canActivate: [LoginGuard]
    },
    {
        path: 'tippuebersicht',
        component: RaceOverviewComponent,
        canActivate: [LoginGuard]
    },
    {
        path: 'liveuebersicht',
        component: LiveOverviewComponent,
        canActivate: [LoginGuard]
    },
    {
        path: 'bonusuebersicht',
        component: BonusOverviewComponent,
        canActivate: [LoginGuard]
    },    
    {
        path: 'qualifying',
        component: QualifyingResultComponent,
        canActivate: [AdminGuard]
    },
    {
        path: 'tippnachtrag',
        component: PredictionAdminComponent,
        canActivate: [AdminGuard]
    },
    {
        path: 'ergebnis',
        component: ResultComponent,
        canActivate: [AdminGuard]
    },
    {
        path: 'bonusergebnis',
        component: BonusResultComponent,
        canActivate: [AdminGuard]
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [GuestGuard]
    },
    {
        path: 'change-password',
        component: ChangePasswordComponent,
        canActivate: [LoginGuard]
    },
    {
        path: '',
        redirectTo: '/termine',
        pathMatch: 'full'
    },
    {
        path: '**',
        component: PageNotFoundComponent
    }
];

@NgModule( {
    imports: [
        RouterModule.forRoot(
            appRoutes,
            { enableTracing: false } // <-- debugging purposes only
        )
    ],
    exports: [
        RouterModule
    ]
} )
export class AppRoutingModule { }
