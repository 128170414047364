import { Injectable } from '@angular/core';



import { ApiService } from './api.service';

import { Driver } from '../model/driver';

@Injectable()
export class DriverService {
    private tracksUrl = 'api/drivers';  // URL to web api

    constructor( private apiService: ApiService ) { }

    getDrivers( season: number ): Promise<Driver[]> {

        return this.apiService.get<Driver[]>( this.tracksUrl, { season: season } )
            .toPromise();
    }

}
