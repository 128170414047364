export class User {
  userID: number;
  userName: string;
  userMail: string;
  active: boolean;
  predictionNotification: boolean;
  resultNotification: boolean;
  userRoles: UserRoles[];
  refreshAuthTokenDate: Date;
}

export class UserRoles {
    authority: string;
    role: string;
}
