import { DataSource } from '@angular/cdk/collections';
import { Observable ,  Subscription ,  BehaviorSubject ,  of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

import {
    BonusPrediction,
    BonusQuestion,
    User
} from '../../model';
import {
    BonusService,
    DriverService,
    SelectionService,
    UserService
} from "../../service";
import { catchError, finalize, mergeMap, flatMap, map, every, concatMap, concatAll, mergeAll } from "rxjs/operators";

export class BonusQuestionDataSource extends DataSource<BonusPrediction>{

    private currentUser: User;
    private bonusPredictionSubject = new BehaviorSubject<BonusPrediction[]>( [] );

    private loadingSubject = new BehaviorSubject<boolean>( false );

    public loading$ = this.loadingSubject.asObservable();

    constructor( private bonusService: BonusService, private selectionService: SelectionService, private userService: UserService ) {
        super();
        if ( this.currentUser == null ) {
            this.currentUser = userService.getCurrentUser();
        }
    }

    connect(): Observable<BonusPrediction[]> {
        return this.bonusPredictionSubject.asObservable();

    }

    disconnect() {
        this.bonusPredictionSubject.complete();
    }

    getBonusPredictions( season: number ) {
        this.loadingSubject.next( true );
        if ( season == null ) {
            season = new Date().getFullYear();
        }
        this.bonusService.getBonusPredictionsBySeasonAndUser( season, this.currentUser )
            .subscribe(
            bonusPredictions => {
                this.bonusPredictionSubject.next( bonusPredictions );
                this.loadingSubject.next( false );
            },
            error => {
                this.loadingSubject.next( false );
                of( [] );
            }
            );
    }

}
