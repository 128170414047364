import { Track } from "./track";
import { Driver } from "./driver";
import { User } from "./user";

export class Result {
    id: number;
    season: number;
    track: Track;
    positions: Array<Driver> = new Array<Driver>();
    fastestLap: Driver;

}

