import { Injectable } from '@angular/core';


import { Observable } from 'rxjs';

import { ApiService } from './api.service';

import { Prediction } from '../model/prediction';

@Injectable()
export class PredictionService {
    private predictionsUrl = 'api/predictions';  // URL to web api

    constructor( private apiService: ApiService ) { }

    getPrediction( season: number, trackNo: number, userId: number ): Observable<Prediction> {

        return this.apiService.get<Prediction>( this.predictionsUrl + "/user/" + userId + "/season/" + season + "/track/" + trackNo );
//            .toPromise()
//            .then( response => {
//                console.log( "response in getPredictionService: " );
//                console.log( response );
//                return response as Prediction;
//            } );
    }

    savePrediction( prediction: Prediction ): Promise<Prediction> {

//        console.info( "savePrediction... track: " + prediction.track + " user: " + prediction.user + " predictionID: " + prediction.id );

        let jsonBody = JSON.stringify( prediction );
//        console.info( "jsonBody: " + jsonBody );

        return this.apiService.post( this.predictionsUrl, jsonBody )
            .toPromise()
            .then( response => ( response as Prediction ) );
    }

    updatePrediction( prediction: Prediction ): Promise<Prediction> {

//        console.info( "savePrediction... track: " + prediction.track + " user: " + prediction.user + " predictionID: " + prediction.id );

        let jsonBody = JSON.stringify( prediction );
//        console.info( "jsonBody: " + jsonBody );

        return this.apiService.put( this.predictionsUrl + "/" + prediction.id, jsonBody )
            .toPromise()
            .then( response => ( response as Prediction ) );
    }
}
