import { Component, OnInit } from '@angular/core';

import {
    SelectionService,
    TrackService
} from "../service";

@Component( {
    selector: 'season-selector',
    templateUrl: './season-selector.component.html',
    styleUrls: ['./season-selector.component.css']
} )

export class SeasonSelectorComponent implements OnInit {

    constructor( private trackService: TrackService, private selectionService: SelectionService ) { }

    seasons: number[];
    _selectedSeason: number;

    getSeasons(): void {
        this.trackService.getSeasons().subscribe( seasons => this.seasons = seasons );
    }

    ngOnInit(): void {
        if ( this._selectedSeason == null ) {
            this._selectedSeason = new Date().getFullYear();
        }
        this.getSeasons();
    }

    onChange(): void {
        this.selectionService.setSelectedSeason( this._selectedSeason );
    }

}
