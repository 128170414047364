import { Track } from "./track";
import { Driver } from "./driver";
import { User } from "./user";

export class Prediction {
  id: number;
  season: number;
  track: Track;
  user: User;
  predictions: Array<Driver> = new Array<Driver>();
  fastestLap: Driver;

}

