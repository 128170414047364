import { HttpClient, HttpHeaders, HttpResponse, HttpRequest, HttpEventType, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, filter, catchError } from 'rxjs/operators';

import { serialize } from '../shared/utilities/serialize';

export enum RequestMethod {
    Get = 'GET',
    Head = 'HEAD',
    Post = 'POST',
    Put = 'PUT',
    Delete = 'DELETE',
    Options = 'OPTIONS',
    Patch = 'PATCH'
}

@Injectable()
export class ApiService {

    headers = new HttpHeaders( {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    } );

    constructor( private http: HttpClient, private injector: Injector ) { }

    get<T>( path: string, args?: any ): Observable<any> {
        const options = {
            headers: this.headers,
            withCredentials: true
        };

        if ( args ) {
            options['params'] = serialize( args );
        }

        return this.http.get<T>( path, options ).pipe(
            catchError( this.checkError.bind( this ) ));
    }

    post<T>( path: string, body: any, customHeaders?: HttpHeaders ): Observable<any> {
        return this.request<T>( path, body, RequestMethod.Post, customHeaders );
    }

    put( path: string, body: any ): Observable<any> {
        return this.request( path, body, RequestMethod.Put );
    }

    delete( path: string, body?: any ): Observable<any> {
        return this.request( path, body, RequestMethod.Delete );
    }

    private request<T>( path: string, body: any, method = RequestMethod.Post, custemHeaders?: HttpHeaders ): Observable<any> {
        const req = new HttpRequest( method, path, body, {
            headers: custemHeaders || this.headers,
            withCredentials: true
        } );

        return this.http.request<T>( req ).pipe(
            filter( response => response instanceof HttpResponse ),
            map(( response: HttpResponse<any> ) => response.body ),
            catchError( error => this.checkError( error ) ));
    }

    private getRouter(): Router {
        return this.injector.get( Router );
    }

    private checkError( error: any ): any {
        if ( error instanceof HttpErrorResponse && error.status === 401 ) {
            this.getRouter().navigateByUrl( '/login' );
        }
        return Promise.reject( error.message || error );
    }

}
