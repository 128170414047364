import { Injectable } from '@angular/core';



import { 
    Track,
    User
} from "../model";
import { Subject } from "rxjs";

@Injectable()
export class SelectionService {


  constructor() { }

  // Observables
  selectedTrack = new Subject<Track>();
  selectedSeason = new Subject<number>();
  selectedUser = new Subject<User>();
  
  setSelectedTrack(track: Track) {
    this.selectedTrack.next(track);
  }
  
  setSelectedSeason(season: number) {
//      console.log("set selected season:");
//      console.log(season);
    this.selectedSeason.next(season);
  }
  
  setSelectedUser(user: User){
      this.selectedUser.next(user);
  }

}
