import { Component, OnInit, Input, OnDestroy, forwardRef, Output, EventEmitter } from '@angular/core';
import { Subscription } from "rxjs";
import { flatMap } from "rxjs/operators";
import { MediaMatcher } from '@angular/cdk/layout';

import { MatTableDataSource } from '@angular/material/table';

import {
    RaceOverview,
    Driver,
    Result,
    User,
    Track
} from '../model';
import {
    SelectionService,
    RaceOverviewService,
    ResultService,
    UserService
} from "../service";

import { RaceOverviewDataSource } from "./race-overview.datasource";

@Component( {
    selector: 'race-overview',
    templateUrl: './race-overview.component.html',
    styleUrls: ['./race-overview.component.css']
} )

export class RaceOverviewComponent implements OnInit, OnDestroy {

    @Input() season: number;
    @Input() selectedTrack: Track;
    seasonSubscription: Subscription;
    trackSubscription: Subscription;
    displayedColumns = ['position', 'positionDiff', 'userName', 'prediction1', 'prediction2', 'prediction3', 'prediction4', 'prediction5', 'prediction6', 'prediction7', 'prediction8', 'prediction9', 'prediction10', 'fastestLap', 'racepoints', 'totalbonuspoints', 'totalwins', 'totalpoints'];
    dataSource: RaceOverviewDataSource;
    result: Result;
    mobileQuery: MediaQueryList;
    private _mobileQueryListener: ( event ) => void;

    //    breakPointSubscription: Subscription;
    //    showAllColumns = false;


    constructor( private selectionService: SelectionService, private raceOverviewService: RaceOverviewService, private resultService: ResultService, public userService: UserService, media: MediaMatcher ) {
        this.mobileQuery = media.matchMedia( '(max-width: 1200px)' );
        this.mobileQuery.addListener( this._mobileQueryListener );
        //        this.showAllColumns = !this.mobileQuery.matches;
        this.seasonSubscription = selectionService.selectedSeason.subscribe(
            season => {
                this.season = season;
            } );
        this.trackSubscription = selectionService.selectedTrack.subscribe(
            track => {
                //                console.log( "change selected track?" );
                //                console.log(track);
                if ( ( track ) && ( this.selectedTrack ) && ( track.trackId == this.selectedTrack.trackId ) ) {
                    //                    console.log( "sameTrack...no need to reload data" );
                } else {
                    //                    console.log(this.selectedTrack);
                    this.selectedTrack = track;
                    this.getResult( this.season, this.selectedTrack.trackNo );
                    this.dataSource.getRaceOverviews( this.season, this.selectedTrack );
                    //                    this.showAllColumns = !this.mobileQuery;
                    //                    this.setShareText();
                }
            } );
    }

    ngOnInit(): void {
        if ( this.season == null ) {
            this.season = new Date().getFullYear();
        }
        this.dataSource = new RaceOverviewDataSource( this.raceOverviewService, this.resultService );
    }

    ngOnDestroy() {
        // prevent memory leak when component destroyed
        this.seasonSubscription.unsubscribe();
        this.trackSubscription.unsubscribe();
        this.mobileQuery.removeListener( this._mobileQueryListener );
        this.mobileQuery = null;
    }

    getResult( season: number, trackNo: number ) {
        this.resultService.getResult( season, trackNo ).subscribe( result => this.result = result );
    }

    shareWA() {
        let waLink = "https://wa.me/?text="
        let link = "\nhttps://www.f1asd-tippen.de/tippuebersicht";
        let text = "Das Ergebnis für den GP von " + this.selectedTrack.trackName + " wurde eingetragen.\nDer Tagessieg geht mit ";
        let totalTop3: any[] = [];
        let raceTop3: any[] = [];
        let raceOverviews = this.dataSource.raceOverviews;
        totalTop3.push( { name: raceOverviews[0].user.userName, points: raceOverviews[0].totalSeasonPoints } );
        totalTop3.push( { name: raceOverviews[1].user.userName, points: raceOverviews[1].totalSeasonPoints } );
        totalTop3.push( { name: raceOverviews[2].user.userName, points: raceOverviews[2].totalSeasonPoints } );

        // TODO: filter alle tagessieger...
        let sortedArr = raceOverviews.sort(function( a, b ) { return b.racePoints - a.racePoints; });
        raceTop3.push( { name: sortedArr[0].user.userName, points: sortedArr[0].racePoints } );
        raceTop3.push( { name: sortedArr[1].user.userName, points: sortedArr[1].racePoints } );
        raceTop3.push( { name: sortedArr[2].user.userName, points: sortedArr[2].racePoints } );
        console.log(totalTop3);
        console.log(raceTop3);
        text = text + raceTop3[0].points + " Punkten an " + raceTop3[0].name + " vor " + raceTop3[1].name + " (" + raceTop3[1].points + ") und " + raceTop3[2].name + " (" + raceTop3[2].points + ").";
        text = text + "\nIn der Gesamtwertung liegt " + totalTop3[0].name + " mit " + totalTop3[0].points + " Punkten vor " + totalTop3[1].name + " (" + totalTop3[1].points + ") und " + totalTop3[2].name + " (" + totalTop3[2].points + ").";
        text = text + "\nDas nächste Rennen ist der GP von " + ". Start:  am " + " um " + " Uhr.";
        
        window.open( encodeURI( waLink + text + link ) );
    }

}
