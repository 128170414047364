import { Component, OnInit, Input, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, NG_VALIDATORS, FormControl, FormBuilder, FormArray } from "@angular/forms";

import { Subscription, BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ToasterService, ToasterConfig, Toast } from 'angular2-toaster';

import { Driver } from '../model/driver';
import { DriverService } from '../service/driver.service';
import { Track } from "../model/track";
import { SelectionService } from "../service/selection.service";
import { Qualifying } from "../model/qualifying";
import { QualifyingService } from "../service/qualifying.service";
import { CustomValidators } from "../validators/custom-validators";

@Component( {
    selector: 'qualifying-result',
    templateUrl: './qualifying-result.component.html',
    styleUrls: ['./qualifying-result.component.css'],
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => QualifyingResultComponent ), multi: true }
    ]
} )

export class QualifyingResultComponent implements OnInit, OnDestroy {
    drivers: Driver[];
    season: number;
    @Input() selectedTrack: Track;
    trackSubscription: Subscription;
    qualifyingResult: Qualifying;
    saveErrors: string;
    qualifyingKeys: any;
    myForm: FormGroup;
    positionsFormArray: FormArray;
    propagateChange: any = () => { };

    private loadingSubject = new BehaviorSubject<boolean>( false );
    public loading$ = this.loadingSubject.asObservable();

    public toasterConfig: ToasterConfig = new ToasterConfig( {
        animation: 'fade',
        positionClass: 'toast-center',
        limit: 1
    } );

    constructor( private fb: FormBuilder, private driverService: DriverService, private selectionService: SelectionService, private qualifyingService: QualifyingService, private toasterService: ToasterService ) {
        this.positionsFormArray = new FormArray( [] );
        this.myForm = this.fb.group( {
            'qualifying': new FormGroup( {
                'positions': this.positionsFormArray
            }, CustomValidators.qualifying )
        } );
        this.trackSubscription = selectionService.selectedTrack.subscribe(
            track => {
                this.qualifyingResult = null;
                this.selectedTrack = track;
                this.getQualifying();
            } );
    }

    getDrivers(): void {
        this.driverService.getDrivers( this.season ).then( drivers => this.drivers = drivers );
    }

    getQualifying(): void {
        this.loadingSubject.next( true );
        this.qualifyingService.getQualifying( this.season, this.selectedTrack.trackNo ).subscribe(
            qualifying => {
                this.qualifyingResult = qualifying;
                this.loadingSubject.next( false );
            },
            error => {
                this.qualifyingResult = new Qualifying();
                this.loadingSubject.next( false );
            }
        );
        this.propagateChange( this.qualifyingResult );
    }


    onSave(): void {
        this.loadingSubject.next( true );
        this.qualifyingResult.track = this.selectedTrack;
        this.qualifyingResult.season = this.season;
        this.qualifyingService.saveQualifying( this.qualifyingResult ).subscribe(
            value => {
                this.qualifyingResult = value;
                this.loadingSubject.next( false );
                this.toasterService.pop( 'success', 'Qualifying gespeichert', 'Die Startaufstellung für das Rennen ' + this.qualifyingResult.track.trackName + ' wurde erfolgreich gespeichert.' );
            }, error => {
                this.saveErrors = error;
                this.loadingSubject.next( false );
                this.toasterService.pop( 'error', 'Fehler', 'Die Startaufstellung für das Rennen ' + this.qualifyingResult.track.trackName + ' konnte nicht gespeichert werden.' );
            } );
    }

    ngOnInit(): void {
        if ( this.season == null ) {
            this.season = new Date().getFullYear();
        }
        this.getDrivers();
        this.getDriverCount().subscribe(
            driverCount => {
                this.qualifyingKeys = new Array( driverCount );
                this.initFormGroup( driverCount );
            }
        );
    }

    ngOnDestroy() {
        // prevent memory leak when component destroyed
        this.trackSubscription.unsubscribe();
    }

    compareDriver( d1: Driver, d2: Driver ): boolean {
        return d1 && d2 ? d1.id === d2.id : d1 === d2;
    }

    validate( c: FormGroup ) {
        return CustomValidators.qualifying( c.value );
    }

    getDriverCount(): Observable<number> {
        return this.qualifyingService.getDriverCount( this.season ).pipe(
            map(
                driverCount => {
                    if ( driverCount > 0 ) {
                        return driverCount;
                    } else {
                        return 24;
                    }
                }, error => {
                    return 24;
                } ) );
    }

    initFormGroup( driverCount: number ) {
        for ( var i = 0; i < driverCount; i++ ) {
            this.positionsFormArray.push( new FormControl( '' ) );
        }
    }

}
