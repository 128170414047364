import { Inject } from '@angular/core';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DisplayMessage } from '../shared/models/display-message';
import { Subscription, Observable, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import {
    UserService,
    AuthService
} from '../service';
import { User } from "../model/user";

@Component( {
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
} )
export class LoginComponent implements OnInit, OnDestroy {
    title = 'Login';
    form: FormGroup;
    //  private user: User;
    //  private userSubscription: Subscription;

    /**
     * Boolean used in telling the UI
     * that the form has been submitted
     * and is awaiting a response
     */
    submitted = false;

    /**
     * Notification message from received
     * form request or router
     */
    notification: DisplayMessage;

    returnUrl: string;
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private userService: UserService,
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder
    ) {

    }

    ngOnInit() {
        this.route.params.pipe(
            takeUntil( this.ngUnsubscribe ) )
            .subscribe(( params: DisplayMessage ) => {
                this.notification = params;
            } );
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.form = this.formBuilder.group( {
            username: ['', Validators.compose( [Validators.required, Validators.minLength( 3 ), Validators.maxLength( 64 )] )],
            password: ['', Validators.compose( [Validators.required, Validators.minLength( 3 ), Validators.maxLength( 32 )] )]
        } );
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onResetCredentials() {
        this.userService.resetCredentials().pipe(
            takeUntil( this.ngUnsubscribe ) )
            .subscribe( res => {
                if ( res.result === 'success' ) {
                    alert( 'Password has been reset to 123 for all accounts' );
                } else {
                    alert( 'Server error' );
                }
            } );
    }

    onSubmit() {
        /**
         * Innocent until proven guilty
         */
        this.notification = undefined;
        this.submitted = true;

        this.authService.login( this.form.value ).pipe(
            // show me the animation
            delay( 1000 ) )
            .subscribe( data => {
                this.userService.getUserInfo().subscribe();
                this.router.navigate( [this.returnUrl] );
            },
            error => {
                this.submitted = false;
                this.notification = { msgType: 'error', msgBody: 'Incorrect username or password.' };
            } );

    }


}
