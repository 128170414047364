import { Component } from '@angular/core';
import {
    UserService,
    AuthService
} from '../../service';
import { User } from "../../model/user";
import { Router } from '@angular/router';

import { SidenavService } from '../side-nav/side-nav.service';

@Component( {
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
} )
export class HeaderComponent {

    constructor(
        private userService: UserService,
        private authService: AuthService,
        private router: Router,
        public sidenavService: SidenavService
    ) { }

    logout() {
        this.authService.logout().subscribe( res => {
            this.router.navigate( ['/login'] );
        } );
    }

    hasSignedIn() {
        return !!this.userService.getCurrentUser();
    }

    userName() {
        const user = this.userService.getCurrentUser();
        return user.userName;
    }
    
    

}
