import { Injectable } from '@angular/core';


import { Observable } from 'rxjs';

import { ApiService } from './api.service';

import { RaceOverview, Result } from '../model';

@Injectable()
export class RaceOverviewService {
    private raceOverviewsUrl = 'api/raceoverviews';  // URL to web api

    constructor( private apiService: ApiService ) { }

    getRaceOverviews( season: string, trackNo: number ): Observable<RaceOverview[]> {
        return this.apiService.get<RaceOverview[]>( this.raceOverviewsUrl, { season: season, trackNo: trackNo } );
    }
    
    simulate( result: Result ): Observable<RaceOverview[]> {
        return this.apiService.post<RaceOverview[]>( this.raceOverviewsUrl + "/simulate", result );
    }

}
