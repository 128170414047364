<mat-toolbar color="primary" class="app-navbar">
<button hamburger mat-button title="menu" (click)="sidenavService.toggle.emit()">
	<span class="mat-button-wrapper"><mat-icon class="mat-icon"
			role="img" svgicon="menu" aria-hidden="true">
		<svg xmlns="http://www.w3.org/2000/svg" focusable="false"
			viewBox="0 0 24 24">
			<path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path></svg></mat-icon></span>
	<div class="mat-button-ripple mat-ripple" matripple=""></div>
	<div class="mat-button-focus-overlay"></div>
</button>
<button mat-button mat-ripple routerLink="/" class="greeting-button">
	<span>F1 ASD Tippspiel</span>
</button>
<button mat-button mat-ripple routerLink="/" class="greeting-hamburger">
	<mat-icon>home</mat-icon>
</button>

<div class="right">
	<div fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="flex-end center">
		<!-- 		<button *ngIf="!hasSignedIn()" routerLink="/signup" mat-button -->
		<!-- 			mat-ripple> -->
		<!-- 			<span>Sign up</span> -->
		<!-- 		</button> -->
		<button *ngIf="!hasSignedIn()" routerLink="/login" mat-button
			mat-ripple>
			<span>Login</span>
		</button>
		<button class="greeting-button" *ngIf="hasSignedIn()" mat-button
			 [matMenuTriggerFor]="accountMenu">
			<span>Moin {{userName()}}!</span>
		</button>
		<button class="greeting-hamburger" *ngIf="hasSignedIn()"
			mat-icon-button [matMenuTriggerFor]="accountMenu">
			<mat-icon>account_circle</mat-icon>
		</button>
		<mat-menu #accountMenu class="app-header-accountMenu"
			yposition="below" xposition="after" [overlapTrigger]="false"> <app-account-menu></app-account-menu>
		</mat-menu>
	</div>
</div>
</mat-toolbar>