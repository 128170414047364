import { Component, OnInit } from '@angular/core';

import { Observable } from "rxjs";

import {
    SelectionService,
    UserService
} from "../service";
import {
    User
} from "../model";

@Component( {
    selector: 'user-selector',
    templateUrl: './user-selector.component.html',
    styleUrls: ['./user-selector.component.css']
} )

export class UserSelectorComponent implements OnInit {

    constructor( private userService: UserService, private selectionService: SelectionService ) { }

    users: User[];
    _selectedUser: User;

    getUsers() {
        return this.userService.getAllActive();
    }

    ngOnInit(): void {
        this.getUsers().subscribe( users => {
            this.users = users;
            if ( this._selectedUser == null ) {
                this._selectedUser = this.userService.getCurrentUser();
                this.onChange();
            }
        } );
    }

    onChange(): void {
        this.selectionService.setSelectedUser( this._selectedUser );
    }
    
    compareFn( u1: User, u2: User ): boolean {
        return u1 && u2 ? u1.userID === u2.userID : u1 === u2;
    }

}
