<div class="content" fxLayout="row" fxLayoutAlign="center">
	<div class="container">
		<h2>{{title}}</h2>

		<p [class]="notification.msgType" *ngIf="notification">{{notification.msgBody}}</p>
			<form *ngIf="!submitted" [formGroup]="form" (ngSubmit)="onSubmit()"
				#loginForm="ngForm" class="login-form">
				<mat-form-field>
					<input matInput formControlName="username" required
						placeholder="Benutzername">
				</mat-form-field>
				<mat-form-field>
					<input matInput formControlName="password" required type="password"
						placeholder="Passwort">
				</mat-form-field>
				<button class="btn btn-primary col-sm-2"
					[disabled]="!loginForm.form.valid">Login</button>
			</form>
	</div>
</div>