import { Injectable } from '@angular/core';



import { ApiService } from './api.service';

import { Result } from '../model/';
import { Observable } from "rxjs";

@Injectable()
export class ResultService {
    private url = 'api/results';  // URL to web api

    constructor( private apiService: ApiService ) { }

    getResult( season: number, trackNo: number ): Observable<Result> {

        return this.apiService.get<Result>( this.url, { season: season, trackNo: trackNo } );
    }
    
    saveResult(result: Result): Observable<Result> {
        return this.apiService.post<Result>( this.url, result );
    }
    
    updateResult(result: Result): Observable<Result> {
        return this.apiService.put( this.url + "/" + result.id, result );
    }

}
