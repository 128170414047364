<toaster-container [toasterconfig]="toasterConfig"></toaster-container>
<div class="table-container mat-elevation-z8">
<div class="spinner-container" *ngIf="dataSource.loading$ | async">
    <mat-spinner style="margin: 0 auto;"></mat-spinner>
</div>
	<mat-table #table [dataSource]="dataSource">
		<!-- Deadline Column -->
		<ng-container matColumnDef="deadline">
			<mat-header-cell *matHeaderCellDef> Termin </mat-header-cell>
			<mat-cell *matCellDef="let bonusPrediction">
				{{bonusPrediction.bonusQuestion.deadline | date:'dd. MMMM y, HH:mm'}} Uhr
			</mat-cell>
		</ng-container>
		
		<!-- Bonusquestion Column -->
		<ng-container matColumnDef="bonusquestion">
			<mat-header-cell *matHeaderCellDef> Bonusfrage </mat-header-cell>
			<mat-cell *matCellDef="let bonusPrediction">
				{{bonusPrediction.bonusQuestion.question}}
			</mat-cell>
		</ng-container>
		
		<!-- Points Column -->
		<ng-container matColumnDef="bonuspoints">
			<mat-header-cell *matHeaderCellDef> Punkte </mat-header-cell>
			<mat-cell *matCellDef="let bonusPrediction">
				{{bonusPrediction.bonusQuestion.points}} 
			</mat-cell>
		</ng-container>
		
		<!-- Prediction Column -->
		<ng-container matColumnDef="prediction">
			<mat-header-cell *matHeaderCellDef> Tipp </mat-header-cell>
			<mat-cell *matCellDef="let bonusPrediction">
				<mat-form-field>
					<mat-select [compareWith]="compareDriver" required
						[disabled]="!isPredictionValid(bonusPrediction)"
						[(ngModel)]="bonusPrediction.bonusPredictionDriver"
						(selectionChange)="onChangePrediction(bonusPrediction)">
						<mat-option	*ngFor="let driver of drivers" [value]="driver">
							{{ driver.driverName }} 
						</mat-option>
					</mat-select>
				</mat-form-field>
			</mat-cell>
		</ng-container>
		
		<mat-header-row	*matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
	</mat-table>
</div>
