<!--The content below is only a placeholder and can be replaced.-->
<!-- <h4 *ngIf="(qualifying)">Startaufstellung {{qualifying.track.trackName}} {{qualifying.season}}</h4> -->
<!-- 
<mat-grid-list *ngIf="(qualifying) && (qualifying.positions)" [cols]="(qualifying.positions.length / 2)" 
				gutterSize="5px">
	<mat-grid-tile
		*ngFor="let driver of qualifying.positions; let i = index">
		<mat-card *ngIf="(driver)" class="card">
		  <mat-card-header>
		    <mat-card-title>Platz {{i+1}}</mat-card-title>
		  </mat-card-header>
		  <mat-card-content>
		    <span class="badge badge-info">
              {{driver.driverNo}}
            </span>
            {{driver.driverName}}
		  </mat-card-content>
		</mat-card>
	</mat-grid-tile>
</mat-grid-list>
 -->	

<!--d-flex align-content-start flex-wrap-->
<mat-expansion-panel *ngIf="(qualifying)" [expanded]="!mobileQuery.matches">
	<mat-expansion-panel-header>
  		<mat-panel-title>
    		Startaufstellung {{qualifying?.track?.trackName}} {{qualifying?.season}}
  		</mat-panel-title>
	</mat-expansion-panel-header>
	<div *ngIf="(qualifying) && (qualifying.positions)" class="container">
	  <div class="d-flex align-content-start flex-wrap">
	    <div *ngFor="let driver of qualifying.positions; let i = index">
	      <div *ngIf="(driver)" class="card text-center">
	      <!-- desktop version -->
	        <div *ngIf="!mobileQuery.matches" class="card-block m-2">
	          <h5 class="card-title">Platz {{i+1}}</h5>
	          <p class="card-text">
	            <span class="badge badge-info">
	              {{driver.driverNo}}
	            </span>
	            {{driver.driverName}}
	          </p>
	        </div>
	        <!-- mobile version -->
	       <div *ngIf="mobileQuery.matches" class="card-block m-2">
	          <h6 class="card-title">{{i+1}}.</h6>
	          <p class="card-text">
	            <span class="badge badge-info">
	              {{driver.driverNo}}
	            </span>
	            {{driver.trigram}}
	          </p>
	        </div>
	      </div>
	    </div>
	  </div>
	</div>
</mat-expansion-panel>
 